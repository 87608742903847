import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login/Login.vue'
import Chat from '@/views/Chat/Chat.vue'
import Contact from '@/views/Contacts/Contacts.vue'
import Other from '@/views/Other/Other.vue'
import Schedule from '@/views/Schedule/Schedule'
import Profile from '@/views/Profile/Profile'
import Files from '@/views/Files/Files'
import Identification from '@/views/Identification/Identification'
import IdentificationSelect from '@/views/IdentificationSelect/IdentificationSelect'
import ProfileRegister from '@/views/ProfileRegister/ProfileRegister'
import UserRegistered from '@/views/UserRegistered'
import IsOwner from '@/views/IsOwner'
import IsFamily from '@/views/IsFamily'
import IsInvitation from '@/views/IsInvitation'
import UserConfirmation from '@/views/UserConfirmation'
import OwnerConfirmation from '@/views/OwnerConfirmation'
import FamilyConfirmation from '@/views/FamilyConfirmation'
import ReissuePassword from '@/views/ReissuePassword/ReissuePassword'
import ResetPassword from '@/views/ResetPassword/ResetPassword'
import OfficeRegister from '@/views/OfficeRegister/OfficeRegister'
import Manage from '@/views/Manage/Manage'
import ManageStaff from '@/views/ManageStaff/ManageStaff'
import ManagePrice from '@/views/ManagePrice/ManagePrice'
import ManagePr from '@/views/ManagePr/ManagePr'
import MapMenu from '@/views/MapMenu/MapMenu'
import ManageIdentification from '@/views/ManageIdentification/ManageIdentification'
import ManageIdentificationSelect from '@/views/ManageIdentificationSelect/ManageIdentificationSelect'
import Platinum from '@/views/Platinum/Platinum'
import PlatinumPage from '@/views/PlatinumPage/PlatinumPage'
import PlatinumConditions from '@/views/PlatinumConditions/PlatinumConditions'
import PlatinumUplode from '@/views/PlatinumUplode/PlatinumUplode'
import PlatinumRegister from '@/views/PlatinumRegister/PlatinumRegister'
import Owner from '@/views/Owner/Owner'
import OwnerRegister from '@/views/OwnerRegister/OwnerRegister'
import Family from '@/views/Family/Family'
import FamilyRegister from '@/views/FamilyRegister/FamilyRegister'
import Invitation from '@/views/Invitation/Invitation'
import Sample from '@/views/Sample'
import ManageRegisterOffice from '@/views/ManageRegisterOffice/ManageRegisterOffice'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'chats' }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { showSideBar: false, needLogin: true }
  },
  {
    path: '/owner',
    name: 'owner',
    component: Owner,
    meta: { showSideBar: false, needLogin: false }
  },
  {
    path: '/family',
    name: 'family',
    component: Family,
    meta: { showSideBar: false, needLogin: false }
  },
  {
    path: '/invitation',
    name: 'invitation',
    component: Invitation,
    meta: { showSideBar: false, needLogin: false }
  },
  {
    path: '/owner_register/:step',
    name: 'owner_register',
    component: OwnerRegister,
    meta: { showSideBar: false, needLogin: false },
    beforeEnter: (to, from, next) => {
      if (from.name === 'office_register') {
        next(false)
      } else {
        next()
      }
    }
  },
  {
    path: '/family_register/:step',
    name: 'family_register',
    component: FamilyRegister,
    meta: { showSideBar: false, needLogin: false }
  },
  {
    path: '/user_registered',
    name: 'user_registered',
    component: UserRegistered,
    meta: { showSideBar: false, needLogin: false }
  },
  {
    path: '/is_owner',
    name: 'is_owner',
    component: IsOwner,
    meta: { showSideBar: false, needLogin: false }
  },
  {
    path: '/is_invitation',
    name: 'is_invitation',
    component: IsInvitation,
    meta: { showSideBar: false, needLogin: false }
  },
  {
    path: '/is_family',
    name: 'is_family',
    component: IsFamily,
    meta: { showSideBar: false, needLogin: false }
  },
  {
    path: '/chat/:chat_type?',
    name: 'chats',
    component: Chat,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/chat/:chat_type/:chat_id',
    name: 'chat',
    component: Chat,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/schedule/:schedule_id?',
    name: 'schedule',
    component: Schedule,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/files',
    name: 'files',
    component: Files,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/identification',
    name: 'identification',
    component: Identification,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/identification/select',
    name: 'identification_select',
    component: IdentificationSelect,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/other/:layer1?/:layer2?',
    name: 'other',
    component: Other,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/profile_register/:step',
    name: 'profile_register',
    component: ProfileRegister,
    meta: { showSideBar: false, needLogin: true }
  },
  {
    path: '/user_confirmation/:confirm_id',
    name: 'user_confirmation',
    component: UserConfirmation,
    meta: { showSideBar: false, needLogin: false }
  },
  {
    path: '/owner_confirmation/:confirm_id',
    name: 'owner_confirmation',
    component: OwnerConfirmation,
    meta: { showSideBar: false, needLogin: false }
  },
  {
    path: '/family_confirmation/:confirm_id',
    name: 'family_confirmation',
    component: FamilyConfirmation,
    meta: { showSideBar: false, needLogin: false }
  },
  {
    path: '/reissue_password',
    name: 'reissue_password',
    component: ReissuePassword,
    meta: { showSideBar: false, needLogin: false }
  },
  {
    path: '/reset_password/:token/:email',
    name: 'reset_password',
    component: ResetPassword,
    meta: { showSideBar: false, needLogin: false }
  },
  {
    path: '/office_register',
    name: 'office_register',
    component: OfficeRegister,
    meta: { showSideBar: false, needLogin: true }
  },
  {
    path: '/manage',
    name: 'manage',
    component: Manage,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/manage/staff',
    name: 'manage_staff',
    component: ManageStaff,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/manage/price',
    name: 'manage_price',
    component: ManagePrice,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/manage/pr',
    name: 'manage_pr',
    component: ManagePr,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/manage/map_menu',
    name: 'map_menu',
    component: MapMenu,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/manage_identification',
    name: 'manage_identification',
    component: ManageIdentification,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/manage_identification/select',
    name: 'manage_identification_select',
    component: ManageIdentificationSelect,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/manage/register_office',
    name: 'manage_register_office',
    component: ManageRegisterOffice,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/platinum',
    name: 'platinum',
    component: Platinum,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/platinum_page:layer1?/:layer2?',
    name: 'platinum_page',
    component: PlatinumPage,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/platinum/conditions',
    name: 'platinum_conditions',
    component: PlatinumConditions,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/platinum/register',
    name: 'platinum_register',
    component: PlatinumRegister,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/platinum/uplode',
    name: 'platinum_uplode',
    component: PlatinumUplode,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/platinum/:layer1?/:layer2?',
    name: 'platinum',
    component: Platinum,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/sample',
    name: 'sample',
    component: Sample,
    meta: { showSideBar: false, needLogin: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
