<template src="./ManageMenu.html" />
<style lang="scss" scoped src="./ManageMenu.scss"></style>
<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import { GET_OFFICE_SEARCH, GET_OTHER_OFFICE_SEARCH } from '@/store/types'

export default defineComponent({
  name: 'ManageMenu',
  components: {},
  props: {},
  setup(_, { root }) {
    const page = computed(() => root.$route.name)
    const me = computed(() => root.$store.getters.me)
    const isManage = computed(() => root.$store.getters.isManage)
    const isRepresentative = computed(
      () => root.$store.getters.isRepresentative
    )

    const office = ref({
      category_id: ''
    })

    console.log('Office function called')

    // 介護事業所の場合：office_numberがNULLでない
    if (me.value.office_number) {
      root.$store
        .dispatch(GET_OFFICE_SEARCH, me.value.office_number)
        .then(response => {
          console.log('Office Search Response:', response.data)

          // APIレスポンスのデータをofficeオブジェクトに設定
          office.value.category_id = response.data.category_id || ''

          console.log('Updated office object:', office.value)
        })
        .catch(error => {
          console.error('Error during office search:', error)
        })
      // 非介護事業所の場合：office_numberがNULL(other_office_numberがNULLでない)
    } else {
      root.$store
        .dispatch(GET_OTHER_OFFICE_SEARCH, me.value.other_office_number)
        .then(response => {
          console.log('Other Office Search Response:', response.data)

          // APIレスポンスのデータをofficeオブジェクトに設定
          office.value.category_id = response.data.category_id || ''

          console.log('Updated office object:', office.value)
        })
        .catch(error => {
          console.error('Error during other office search:', error)
        })
    }

    return {
      page,
      isManage,
      isRepresentative,
      office
    }
  }
})
</script>
