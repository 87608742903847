<template src="./ManageStaff.html" />
<style lang="scss" scoped src="./ManageStaff.scss"></style>
<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import HeaderRight from '@/compornents/HeaderRight/HeaderRight'
import AvatarImg from '@/compornents/AvatarImg/AvatarImg'
import ManageMenu from '@/compornents/ManageMenu/ManageMenu'
import '@/comporsables/useVeeValidate'
import {
  FETCH_OFFICE,
  GET_INVITED_STAFFS,
  POST_STAFF,
  GET_COMPANY_STAFF,
  GET_STAFF_POST
} from '@/store/types'
import {
  MAIL_USAGE_STATUS,
  STAFF_HISTORY_STATUS,
  USER_STATUS
} from '@/constants/constant'

export default defineComponent({
  name: 'ManageStaff',
  title() {
    return 'スタッフ招待 - 介護事業者管理画面'
  },
  components: {
    HeaderRight,
    AvatarImg,
    ManageMenu
  },
  setup(_, { root }) {
    const isSp = computed(() => root.$store.getters.isSp)
    const isManage = computed(() => root.$store.getters.isManage)
    const isRepresentative = computed(
      () => root.$store.getters.isRepresentative
    )

    // SPまたは事業管理者以外の場合はホームへリダイレクト
    if (isSp.value || (!isManage.value && !isRepresentative.value)) {
      root.$router.push({ name: 'home' })
    }

    root.$store.dispatch(FETCH_OFFICE)

    const email = ref('')
    const company_staff = ref()
    const staff_post = ref()
    const judgment = ref(false)

    const fetchStaffData = () => {
      // Promise.allを使用して、両方のAPI呼び出しが完了するまで待機
      Promise.all([
        root.$store.dispatch(GET_COMPANY_STAFF, email.value).then(response => {
          company_staff.value = response.data
          console.log('company_staff:', company_staff.value) // company_staffのログ
        }),
        root.$store.dispatch(GET_STAFF_POST, email.value).then(response => {
          staff_post.value = response.data
          console.log('staff_post:', staff_post.value) // staff_postのログ
        })
      ])
        .then(() => {
          judgment.value = true
          console.log('All data fetched, proceeding to postStaff')
          postStaff() // 両方のデータが取得された後にpostStaffを呼び出す
        })
        .catch(error => {
          console.error('Error fetching staff data:', error)
        })
    }

    // postStaffの関数
    const postStaff = () => {
      console.log('postStaff called')
      if (company_staff.value === false && judgment.value === true) {
        root.$notify({
          title: '',
          message: '当該メールアドレスは他の会社に登録されています',
          type: 'error'
        })
      } else if (staff_post.value === true && judgment.value === true) {
        root.$notify({
          title: '',
          message: '当該メールアドレスは代表者または管理者です',
          type: 'error'
        })
      } else if (judgment.value === true) {
        root.$store
          .dispatch(POST_STAFF, { email: email.value })
          .then(() => {
            email.value = ''
            root.$notify({
              title: '',
              message: 'スタッフを招待しました',
              type: 'success'
            })
            getInvitedStaffs()
          })
          .catch(() => {
            email.value = ''
            root.$store.dispatch(FETCH_OFFICE)
          })
      }
    }

    const page = ref(1)

    // 招待されたスタッフのリストを取得
    const getInvitedStaffs = () => {
      root.$store.dispatch(GET_INVITED_STAFFS, { page: page.value })
    }
    getInvitedStaffs()

    const invitedStaffs = computed(() => root.$store.getters.invited_staffs)

    // ステータスに応じてラベルを変換
    const convertLabel = status => {
      switch (status) {
        case STAFF_HISTORY_STATUS.INVITING:
          return 'label02'
        case USER_STATUS.REGISTRATION:
          return 'label03'
        case USER_STATUS.TEMPORARY:
          return 'label05'
        case STAFF_HISTORY_STATUS.STOPPED:
          return 'label01'
        case USER_STATUS.DELETED:
          return 'label01'
        default:
          return ''
      }
    }

    const invitedStaffsTotalPage = computed(
      () => root.$store.getters.invited_staffs_total_page
    )

    // 次のページ
    const nextPage = () => {
      if (page.value === invitedStaffsTotalPage.value) {
        return
      }
      page.value++
      getInvitedStaffs()
    }

    // 前のページ
    const prevPage = () => {
      if (page.value === 1) {
        return
      }
      page.value--
      getInvitedStaffs()
    }

    return {
      loading: computed(() => root.$store.getters.loading),
      office: computed(() => root.$store.getters.office),
      email,
      postStaff, // postStaffをreturnに追加
      convertLabel,
      invitedStaffs,
      invitedStaffsTotalPage,
      nextPage,
      prevPage,
      MAIL_USAGE_STATUS,
      fetchStaffData
    }
  }
})
</script>
