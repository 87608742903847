<template src="./Messages.html" />
<style lang="scss" scoped src="./Messages.scss"></style>
<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import useMe from '@/comporsables/useMe'
import AvatarImg from '@/compornents/AvatarImg/AvatarImg'
import { formatDate } from '@/utils/date'
import { SET_REPORT_MESSAGES } from '@/store/types'
import {
  CHAT_STATUS,
  CHAT_TYPE,
  URL_REG_EXP,
  USER_CATEGORY
} from '@/constants/constant'
import { canPreview, downloadFile, getExt, replace_all } from '@/utils/common'
import { getS3Url } from '@/comporsables/useAws'

export default defineComponent({
  name: 'Message',
  props: {
    messages: {
      type: Array,
      default: () => []
    },
    selectedChat: {
      type: Object,
      default: () => {}
    },
    isFamily: {
      type: Boolean,
      default: false
    },
    makeReportMode: {
      type: Boolean,
      default: false
    },
    messageHeight: {
      type: Number,
      require: true
    }
  },
  components: {
    AvatarImg
  },
  setup(props, { root, emit }) {
    const isSp = computed(() => root.$store.getters.isSp)
    const loading = computed(() => root.$store.getters.loading)
    const contacts = computed(() => root.$store.getters.contacts)
    const { me } = useMe(root)

    const is_read_all_message = computed(() =>
      props.isFamily ||
      (me.category_id === USER_CATEGORY.FAMILY &&
        props.selectedChat.chat_type === CHAT_TYPE.GROUP)
        ? root.$store.getters.is_read_all_family_message
        : root.$store.getters.is_read_all_message
    )

    const lastReadMessageId = computed(() =>
      props.isFamily ||
      (me.category_id === USER_CATEGORY.FAMILY &&
        props.selectedChat.chat_type === CHAT_TYPE.GROUP)
        ? root.$store.getters.last_read_family_message_id
        : root.$store.getters.last_read_message_id
    )

    const selectedMessageId = ref(0)
    const toggleSelectMessage = id => {
      if (props.selectedChat.status !== CHAT_STATUS.ENABLE) {
        return
      }
      if (selectedMessageId.value === id) {
        selectedMessageId.value = 0
      } else {
        selectedMessageId.value = id
      }
    }

    const clear = () => {
      selectedMessageId.value = 0
    }

    const deleteMessage = message_id => {
      emit('openDeleteMessageConfirm', message_id)
      selectedMessageId.value = 0
    }

    const selectEditMessage = message_id => {
      emit('selectEditMessage', message_id)
      selectedMessageId.value = 0
    }

    const selectQuoteMessage = message_id => {
      emit('selectQuoteMessage', message_id)
      selectedMessageId.value = 0
    }

    const selectReplyMessage = message_id => {
      emit('selectReplyMessage', message_id)
      selectedMessageId.value = 0
    }

    const reportMessages = computed(() => root.$store.getters.reportMessages)
    const view_messages = computed(() => {
      let vm = []
      let show_date = ''
      props.messages.forEach((m, i) => {
        if (m.message.indexOf('[引用') !== -1) {
          const quote = replace_all(m.message, '\n', '@BR@').match(
            /\[引用 (.*)](.*)\[\/引用]/
          )
          let write_msg = replace_all(m.message, '\n', '@BR@')
          write_msg = write_msg.replace(/\[引用 (.*)](.*)\[\/引用]/, '')
          write_msg = replace_all(write_msg, '@BR@', '\n')
          const qd = quote[1].split(',')
          const user_id = parseInt(qd[0].replace('uid=', ''))
          let user = props.selectedChat.members.find(m => m.user_id === user_id)
          if (user === undefined) {
            user = {
              profile_color: '#ddd',
              last_name: '不明',
              first_name: ''
            }
          }
          m.quote = {
            write_msg: replace_all(write_msg, '@BR@', '\n').trimStart(),
            quote_msg: replace_all(quote[2], '@BR@', '\n'),
            user: user,
            updated_at: replace_all(qd[1], 'time=', '')
          }
        }
        if (m.message.indexOf('[返信') !== -1) {
          const replay = replace_all(m.message, '\n', '@BR@').match(
            /\[返信 (.*)](.*)\[\/返信]/
          )
          let write_msg = replace_all(m.message, '\n', '@BR@')
          write_msg = write_msg.replace(/\[返信 (.*)](.*)\[\/返信]/, '')
          write_msg = replace_all(write_msg, '@BR@', '\n')
          const qd = replay[1].split(',')
          const user_id = parseInt(qd[0].replace('uid=', ''))
          let user = props.selectedChat.members.find(m => m.user_id === user_id)
          if (user === undefined) {
            user = {
              profile_color: '#ddd',
              last_name: '不明',
              first_name: ''
            }
          }
          m.reply = {
            write_msg: replace_all(write_msg, '@BR@', '\n').trimStart(),
            reply_msg: replace_all(replay[2], '@BR@', '\n'),
            user: user,
            updated_at: qd[1].replace('time=', '')
          }
        }

        if (m.message.match(URL_REG_EXP)) {
          m.message_with_url = replace_all(
            m.message,
            URL_REG_EXP,
            '<a href="$&" class="url" target="_blank">$&</a>'
          )
        }

        m.selectedReport =
          props.makeReportMode &&
          reportMessages.value.find(rm => rm.message_id === m.message_id)

        if (show_date !== m.created_at.substr(0, 10)) {
          show_date = m.created_at.substr(0, 10)
          vm = [
            ...vm,
            {
              item_type: 'show_date',
              show_date: formatDate(show_date, 'YYYY年MM月DD日')
            }
          ]
        }

        m.item_type = 'message'
        m.can_preview = canPreview(m.file_name)
        vm = [...vm, m]

        if (
          i !== props.messages.length - 1 &&
          lastReadMessageId.value === m.message_id &&
          m.user_id !== me.id
        ) {
          vm = [...vm, { item_type: 'last_read' }]
        }
      })
      return vm
    })

    console.log(view_messages.value)

    const selectReportMessage = message => {
      root.$store.dispatch(SET_REPORT_MESSAGES, {
        message_id: message.message_id,
        created_at: message.created_at,
        message: message.quote
          ? message.quote.write_msg
          : message.reply
          ? message.reply.write_msg
          : message.message,
        name: message.last_name + ' ' + message.first_name
      })
    }

    const download = file => {
      getS3Url(file.path).then(url => downloadFile(url, file.file_name))
    }

    const preview = message => {
      emit('preview', { ...message, ext: getExt(message.file_name) })
    }

    const moveToIndividualChat = userId => {
      const contact = contacts.value.find(c => c.user_id === userId)
      if (!contact) {
        return
      }
      const chat_id = contact.chat_id

      if (props.selectedChat.chat_id === chat_id) {
        return
      }

      root.$router
        .push({
          name: 'chat',
          params: { chat_type: CHAT_TYPE.INDIVIDUAL, chat_id: chat_id }
        })
        .then(() => location.reload())
    }

    const calcHeight = computed(
      () =>
        'calc(100vh - ' +
        (props.messageHeight <= 66 ? 240 : props.messageHeight + 170) +
        'px)'
    )

    return {
      isSp,
      me,
      calcHeight,
      loading,
      selectedMessageId,
      view_messages,
      is_read_all_message,
      clear,
      toggleSelectMessage,
      deleteMessage,
      selectEditMessage,
      selectQuoteMessage,
      selectReplyMessage,
      selectReportMessage,
      download,
      preview,
      moveToIndividualChat
    }
  }
})
</script>
