<template src="./OfficeIdentificationStatus.html" />
<style lang="scss" scoped src="./OfficeIdentificationStatus.scss"></style>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import { ID_CHECKED_STATUS } from '@/constants/constant'
import { GET_OFFICE_IDENTIFICATION } from '@/store/types'

export default defineComponent({
  name: 'officeIdentificationStatus',
  setup(_, { root }) {
    root.$store.dispatch(GET_OFFICE_IDENTIFICATION)
    return {
      isSp: computed(() => root.$store.getters.isSp),
      isManager: computed(() => root.$store.getters.isManager),
      officeIdentificationStatus: computed(
        () => root.$store.getters.officeIdentificationStatus
      ),
      officeIdentificationDenialReasonText: computed(
        () => root.$store.getters.officeIdentificationDenialReasonText
      ),
      ID_CHECKED_STATUS
    }
  }
})
</script>
