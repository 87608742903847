import { render, staticRenderFns } from "./FamilyRegister.html?vue&type=template&id=7125b570&scoped=true&"
import script from "./FamilyRegister.vue?vue&type=script&lang=js&"
export * from "./FamilyRegister.vue?vue&type=script&lang=js&"
import style0 from "./FamilyRegister.scss?vue&type=style&index=0&id=7125b570&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7125b570",
  null
  
)

export default component.exports