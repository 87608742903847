<template src="./Schedule.html" />
<style lang="scss" scoped src="./Schedule.scss"></style>
<script>
import { computed, defineComponent, reactive, ref } from '@vue/composition-api'
import useMe from '@/comporsables/useMe'
import HeaderRight from '@/compornents/HeaderRight/HeaderRight'
import '@fullcalendar/core/vdom'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import jaLocale from '@fullcalendar/core/locales/ja'
import AvatarImg from '@/compornents/AvatarImg/AvatarImg'
import ScheduleRegisterModal from '@/compornents/ScheduleRegisterModal/ScheduleRegisterModal'
import {
  CLEAR_SCHEDULE,
  DELETE_SCHEDULE,
  FETCH_CHATS,
  FETCH_CONTACTS,
  FETCH_NEXT_SCHEDULES,
  FETCH_SCHEDULES,
  PUT_SCHEDULE,
  SELECT_CHAT_TYPE
} from '@/store/types'
import { CHAT_TYPE, LOADING_TYPE_NONE } from '@/constants/constant'

import { formatDate } from '@/utils/date'
import moment from 'moment'
import { getWindowHeight } from '@/utils/window'

export default defineComponent({
  name: 'Schedule',
  title() {
    return 'スケジュール'
  },
  components: {
    HeaderRight,
    AvatarImg,
    ScheduleRegisterModal,
    FullCalendar
  },
  setup(_, { root }) {
    const { me } = useMe(root)
    const isSp = computed(() => root.$store.getters.isSp)

    const calendarDate = reactive({
      start: '',
      end: ''
    })
    const scheduleId = computed(() => root.$route.params.schedule_id)

    root.$store.dispatch(FETCH_CONTACTS)
    root.$store.dispatch(SELECT_CHAT_TYPE, CHAT_TYPE.ALL)
    root.$store.dispatch(FETCH_CHATS, LOADING_TYPE_NONE)

    const fetchSchedules = (loading = false) => {
      root.$store.dispatch(FETCH_SCHEDULES, {
        start_date: calendarDate.start,
        end_date: calendarDate.end,
        loading: loading
      })
      root.$store.dispatch(FETCH_NEXT_SCHEDULES, loading)
    }

    root.$store.dispatch(FETCH_NEXT_SCHEDULES, true)
    const nextSchedules = computed(() => root.$store.getters.nextSchedules)

    const calendarOptions = reactive({
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      initialView: 'dayGridMonth',
      locale: jaLocale,
      events: computed(() => root.$store.getters.schedules),
      eventTimeFormat: { hour: 'numeric', minute: '2-digit' },
      editable: true,
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      selectLongPressDelay: 0,
      headerToolbar: {
        left: 'title',
        center: '',
        right: isSp.value ? 'today prev,next' : 'prev,next'
      },
      dayCellContent: function (e) {
        e.dayNumberText = e.dayNumberText.replace('日', '')
      },
      contentHeight: isSp.value ? 'calc(100vh - 200px)' : 'calc(100vh - 185px)',
      showNonCurrentDates: true,
      views: {
        dayGridMonth: {
          titleFormat: date =>
            isSp.value
              ? `${date.start.year}年${date.start.month + 1}月`
              : `${date.start.year}年${date.start.month + 1}月 月間カレンダー`,
          dayHeaderFormat: date => {
            const week = ['日', '月', '火', '水', '木', '金', '土'][
              date.date.marker.getDay()
            ]
            return isSp.value ? week : week + '曜日'
          }
        },
        timeGridWeek: {
          titleFormat: date =>
            isSp.value
              ? `${date.start.month + 1}月${date.start.day}日 - ${
                  date.end.month + 1
                }月${date.end.day}日`
              : `${date.start.year}年${date.start.month + 1}月${
                  date.start.day
                }日 - ${date.end.month + 1}月${date.end.day}日`,
          dayHeaderFormat: date => {
            const week = ['日', '月', '火', '水', '木', '金', '土'][
              date.date.marker.getDay()
            ]
            // return isSp.value
            //   ? isTablet.value
            //     ? date.date.day + ' (' + week + ')'
            //     : week + date.date.day
            //   : week + '曜日'
            return isSp.value ? week : week + '曜日'
          },
          slotLabelFormat: {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
            meridiem: false //no funciona
          }
        },
        timeGridDay: {
          slotLabelFormat: {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
            meridiem: false //no funciona
          }
        }
      },
      dateClick: onDateClick,
      eventClick: v => onEventClick(v),
      eventDrop: v => onEventDropOrResize(v),
      // eventResize: v => onEventDropOrResize(v),
      select: v => onDateSelect(v),
      datesSet: v => {
        if (
          calendarDate.start !== formatDate(v.start, 'YYYY-MM-DD') ||
          calendarDate.end !==
            moment(v.end).subtract(1, 'day').format('YYYY-MM-DD')
        ) {
          calendarDate.start = formatDate(v.start, 'YYYY-MM-DD')
          calendarDate.end = moment(v.end)
            .subtract(1, 'day')
            .format('YYYY-MM-DD')
          root.$store
            .dispatch(FETCH_SCHEDULES, {
              start_date: calendarDate.start,
              end_date: calendarDate.end,
              loading: true
            })
            .then(() => {
              if (scheduleId && scheduleId.value) {
                selectedSchedule.value = calendarOptions.events.find(
                  e => e.schedule_id === parseInt(scheduleId.value)
                )
                if (selectedSchedule.value) {
                  showDetailScheduleDialog.value = true
                }
              }
            })
        }
      }
    })

    const showRegisterScheduleDialog = ref(false)
    const showDetailScheduleDialog = ref(false)
    const showDeleteScheduleDialog = ref(false)

    const selectedSchedule = ref(undefined)
    const onDateClick = v => {
      console.log('onDateClick', v)
    }
    const onEventClick = v => {
      console.log('onEventClick', v)
      selectedSchedule.value = calendarOptions.events.find(
        e => e.schedule_id === parseInt(v.event.id)
      )
      console.log(selectedSchedule.value)
      showDetailScheduleDialog.value = true
    }
    const onEventDropOrResize = v => {
      console.log('onEventDropOrResize', v.event)
      const schedule = calendarOptions.events.find(
        e => e.schedule_id === parseInt(v.event.id)
      )
      const startDate = formatDate(v.event.start, 'YYYY-MM-DD')
      const startTime = formatDate(v.event.start, 'HH:mm')
      const endDate = v.event.end
        ? formatDate(v.event.end, 'YYYY-MM-DD')
        : startDate
      const endTime = v.event.end ? formatDate(v.event.end, 'HH:mm') : startTime
      const param = {
        ...schedule,
        is_all_day: Number(v.event.allDay),
        start_datetime: startDate + ' ' + startTime,
        end_datetime: endDate + ' ' + endTime,
        color: schedule.colorId,
        user_ids: [...schedule.user_ids, me.id]
      }
      root.$store.dispatch(PUT_SCHEDULE, param).then(() => {
        root.$store.dispatch(CLEAR_SCHEDULE)
        fetchSchedules()
      })
    }

    const registerEvent = ref(undefined)
    const onDateSelect = v => {
      showRegisterScheduleDialog.value = true
      registerEvent.value = v
    }

    const selectNextSchedule = v => {
      selectedSchedule.value = v
      showDetailScheduleDialog.value = true
    }

    const fullCalendar = ref('')
    const selectedView = ref(calendarOptions.initialView)
    const changeView = view => {
      selectedView.value = view
      fullCalendar.value.getApi().changeView(view)
      showSelectView.value = false
    }

    const showSelectView = ref(false)
    const toggleSelectView = () => {
      showSelectView.value = !showSelectView.value
    }
    const selectViewName = computed(
      () =>
        ({
          dayGridMonth: '月間予定',
          timeGridWeek: '週間予定',
          timeGridDay: '日間予定'
        }[selectedView.value])
    )

    const loading = computed(() => root.$store.getters.loading)

    const deleteSchedule = () => {
      const param = { schedule_id: selectedSchedule.value.schedule_id }
      showDeleteScheduleDialog.value = false
      closeRegisterScheduleDialog()
      root.$store.dispatch(DELETE_SCHEDULE, param).then(() => {
        fetchSchedules()
      })
    }

    const closeDetailScheduleDialog = () => {
      showDetailScheduleDialog.value = false
      selectedSchedule.value = undefined
      if (scheduleId.value) {
        root.$router.push({ name: 'schedule' })
      }
    }

    const closeRegisterScheduleDialog = () => {
      showRegisterScheduleDialog.value = false
      selectedSchedule.value = undefined
      if (scheduleId.value) {
        root.$router.push({ name: 'schedule' })
      }
    }

    return {
      me,
      isSp,
      calendarOptions,
      fullCalendar,
      changeView,
      showSelectView,
      toggleSelectView,
      selectViewName,
      selectedView,
      showRegisterScheduleDialog,
      showDetailScheduleDialog,
      showDeleteScheduleDialog,
      loading,
      registerEvent,
      selectedSchedule,
      deleteSchedule,
      closeDetailScheduleDialog,
      closeRegisterScheduleDialog,
      nextSchedules,
      fetchSchedules,
      selectNextSchedule,
      CHAT_TYPE,
      windowHeight: getWindowHeight(50)
    }
  }
})
</script>
