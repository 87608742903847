import { render, staticRenderFns } from "./ManagePr.html?vue&type=template&id=9f5232dc&scoped=true&"
import script from "./ManagePr.vue?vue&type=script&lang=js&"
export * from "./ManagePr.vue?vue&type=script&lang=js&"
import style0 from "./ManagePr.scss?vue&type=style&index=0&id=9f5232dc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f5232dc",
  null
  
)

export default component.exports