import * as http from '@/utils/api'
import {
  DELETE_SCHEDULE,
  FETCH_NEXT_SCHEDULES,
  FETCH_SCHEDULES,
  POST_SCHEDULE,
  PUT_SCHEDULE,
  CLEAR_SCHEDULE
} from './types'
import { ENDPOINT_SCHEDULE, ENDPOINT_SCHEDULES } from '@/constants/endpoint'
import {
  CHAT_TYPE,
  LOADING_TYPE_NEXT_SCHEDULES,
  LOADING_TYPE_NONE,
  LOADING_TYPE_SCHEDULE,
  LOADING_TYPE_SCHEDULES,
  SCHEDULE_COLOR_CODES
} from '@/constants/constant'
import { formatDate, getNDaysAfter, getToday } from '@/utils/date'

const convert = (schedules, user_id, contacts, chats) =>
  schedules.map(s => {
    let formatDateTerm = ''
    if (s.is_all_day === 1) {
      formatDateTerm = formatDate(s.start_datetime, 'M月D日')
    } else if (
      formatDate(s.start_datetime, 'M月D日') ===
      formatDate(s.end_datetime, 'M月D日')
    ) {
      if (
        formatDate(s.start_datetime, 'HH:mm') ===
        formatDate(s.end_datetime, 'HH:mm')
      ) {
        formatDateTerm = formatDate(s.start_datetime, 'M月D日(dddd) HH:mm')
      } else {
        formatDateTerm =
          formatDate(s.start_datetime, 'M月D日(dddd) HH:mm') +
          '〜' +
          formatDate(s.end_datetime, 'HH:mm')
      }
    } else {
      formatDateTerm =
        formatDate(s.start_datetime, 'M月D日(dddd) HH:mm') +
        '〜' +
        formatDate(s.end_datetime, 'M月D日(dddd) HH:mm')
    }

    const user_ids = s.user_ids.filter(id => id !== user_id)

    const contactMembers = contacts
      .filter(c => user_ids.includes(c.id))
      .map(c => ({
        user_id: c.id,
        last_name: c.last_name,
        profile_color: c.profile_color,
        profile_img_url: c.profile_img_url,
        chat_id: c.chat_id
      }))

    const groupMembers = chats
      .filter(c => c.chat_type === CHAT_TYPE.GROUP)
      .map(c => ({
        chat_id: c.chat_id,
        chat_name: c.chat_name,
        members: c.members
          .filter(
            m =>
              user_ids.includes(m.user_id) &&
              !contactMembers.find(cm => cm.user_id === m.user_id)
          )
          .map(m => {
            const contact = contacts.find(c => c.user_id === m.user_id)
            return {
              user_id: m.user_id,
              last_name: m.last_name,
              profile_color: m.profile_color,
              profile_img_url: m.profile_img_url,
              chat_id: contact ? contact.chat_id : null
            }
          })
      }))
      .filter(c => c.members.length > 0)

    return {
      ...s,
      id: s.schedule_id,
      start: s.start_datetime,
      end: s.end_datetime,
      allDay: s.is_all_day === 1,
      color: SCHEDULE_COLOR_CODES[s.color],
      colorId: s.color,
      title: s.title,
      memo: s.memo,
      start_date: s.start_datetime.substr(0, 10),
      start_time: s.start_datetime.substr(11, 5),
      end_date: s.end_datetime.substr(0, 10),
      end_time: s.end_datetime.substr(11, 5),
      formatDateTerm: formatDateTerm,
      user_ids: user_ids,
      contact_members: contactMembers,
      group_members: groupMembers
    }
  })

export default {
  state: {
    schedules: [],
    nextSchedules: []
  },
  mutations: {
    [FETCH_SCHEDULES](state, responseData) {
      state.schedules = responseData
    },
    [FETCH_NEXT_SCHEDULES](state, responseData) {
      state.nextSchedules = responseData
    },
    [CLEAR_SCHEDULE](state) {
      state.schedules = []
    }
  },
  getters: {
    schedules: (state, getters) =>
      convert(state.schedules, getters.me.id, getters.contacts, getters.chats),
    nextSchedules: (state, getters) =>
      convert(
        state.nextSchedules,
        getters.me.id,
        getters.contacts,
        getters.chats
      )
  },
  actions: {
    [FETCH_SCHEDULES]: async ({ commit, dispatch }, params) => {
      await http
        .get(
          ENDPOINT_SCHEDULES,
          params,
          params.loading ? LOADING_TYPE_SCHEDULES : LOADING_TYPE_NONE
        )
        .then(response => {
          commit(FETCH_SCHEDULES, response.data)
        })
        .catch(error => {
          console.log(error)
          http.handleError(dispatch, error)
        })
    },
    [FETCH_NEXT_SCHEDULES]: async ({ commit, dispatch }, loading) => {
      await http
        .get(
          ENDPOINT_SCHEDULES,
          {
            start_date: getToday(),
            end_date: getNDaysAfter(6)
          },
          loading ? LOADING_TYPE_NEXT_SCHEDULES : LOADING_TYPE_NONE
        )
        .then(response => {
          commit(FETCH_NEXT_SCHEDULES, response.data)
        })
        .catch(error => {
          console.log(error)
          http.handleError(dispatch, error)
        })
    },
    [POST_SCHEDULE]: async ({ dispatch }, param) => {
      await http
        .post(ENDPOINT_SCHEDULE, param, LOADING_TYPE_SCHEDULE)
        .catch(error => {
          console.log(error)
          http.handleError(dispatch, error)
        })
    },
    [PUT_SCHEDULE]: async ({ dispatch }, param) => {
      await http
        .put(ENDPOINT_SCHEDULE, param, LOADING_TYPE_SCHEDULE)
        .catch(error => {
          console.log(error)
          http.handleError(dispatch, error)
        })
    },
    [DELETE_SCHEDULE]: async ({ dispatch }, param) => {
      await http
        .del(ENDPOINT_SCHEDULE, { data: param }, LOADING_TYPE_SCHEDULE)
        .catch(error => {
          console.log(error)
          http.handleError(dispatch, error)
        })
    },
    [CLEAR_SCHEDULE]: async ({ commit }) => {
      commit(CLEAR_SCHEDULE)
    }
  }
}
