<template>
  <div v-if="loaded" id="app" :class="{ 'c-scroll': useScroll }">
    <template v-if="show404">
      <div class="info">
        <p>404 Not Found</p>
        <p>ページが見つかりませんでした<br />URLが正しいかご確認ください</p>
      </div>
    </template>
    <template v-else>
      <div v-if="showSidebar" id="container" class="container_flex">
        <SidebarNav />
        <main id="web-chat">
          <div class="tab-content">
            <transition>
              <router-view />
            </transition>
          </div>
        </main>
      </div>
      <div v-else>
        <transition>
          <router-view />
        </transition>
      </div>
    </template>
  </div>
  <div v-else class="loading">読み込み中...</div>
</template>

<style lang="scss" src="./shared/scss/style.scss"></style>
<style lang="scss" scoped>
.v-enter-active {
  transition: transform 0.5s, color 0s;
}

.v-enter {
  opacity: 0;
  transform: translateY(10px);
}

.info {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 22px;
  color: #ddd;
  line-height: 50px;
  text-align: center;
  width: 100%;
}

.loading {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 22px;
  color: #999;
  line-height: 50px;
  text-align: center;
  width: 100%;
}

@media only screen and (min-width: 992px) {
  .container_flex {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
<script>
import {
  computed,
  defineComponent,
  onBeforeMount,
  ref
} from '@vue/composition-api'
import { FETCH_OFFICE, FETCH_ME, SET_IS_SP, SET_IS_TABLET } from '@/store/types'
import SidebarNav from '@/compornents/SidebarNav/SidebarNav'
import { USER_STATUS } from '@/constants/constant'
import { authBeams } from '@/utils/beams'
import { isChrome } from '@/utils/common'

export default defineComponent({
  name: 'App',
  components: {
    SidebarNav
  },
  setup(_, { root }) {
    const loaded = ref(false)

    const useScroll = computed(() =>
      [
        'chats',
        'chat',
        'contact',
        'profile',
        'schedule',
        'manage',
        'manage_staff',
        'manage_price',
        'identification',
        'identification_select',
        'manage_identification',
        'manage_identification_select',
        'reissue_password'
      ].includes(root.$route.name)
    )
    const me = computed(() => root.$store.getters.me)
    const isManage = computed(() => root.$store.getters.isManage)
    if (root.$route.meta.needLogin) {
      root.$store.dispatch(FETCH_ME).then(() => {
        // beams
        if (isChrome()) {
          console.log('Is Chrome!', window.navigator.userAgent)
          if (me.value.user_id) {
            authBeams(me.value.user_id + '')
          }
        } else {
          console.log('Not Chrome!', window.navigator.userAgent)
        }

        // プロフィール登録済チェック
        if (
          root.$route.name !== 'owner_register' &&
          me.value.status === USER_STATUS.TEMPORARY &&
          parseInt(me.value.is_staff) === 0
        ) {
          root.$router.push({ name: 'owner_register', params: { step: 1 } })
        }
        if (
          root.$route.name !== 'family_register' &&
          me.value.status === USER_STATUS.TEMPORARY &&
          parseInt(me.value.is_staff) === 1 &&
          parseInt(me.value.is_family) === 0
        ) {
          root.$router.push({ name: 'family_register', params: { step: 1 } })
        }
        if (
          root.$route.name !== 'profile_register' &&
          me.value.status === USER_STATUS.TEMPORARY &&
          parseInt(me.value.is_staff) === 1 &&
          parseInt(me.value.is_family) !== 0
        ) {
          root.$router.push({ name: 'profile_register', params: { step: 1 } })
        }

        // 決済代行情報登録済チェック
        if (
          root.$route.name !== 'Office_register' &&
          isManage.value &&
          !root.$store.getters.Office
        ) {
          root.$store.dispatch(FETCH_OFFICE).then(() => {
            loaded.value = true
            if (!root.$store.getters.office) {
              root.$router.push({ name: 'office_register' })
            }
          })
        } else {
          loaded.value = true
        }
      })
    } else {
      loaded.value = true
    }

    const show404 = ref(false)
    show404.value = root.$route.name === null

    const nowWidth = ref(0)

    // SP判定
    onBeforeMount(() => {
      const isSp = window.innerWidth <= 991
      const isTablet = isSp && window.innerWidth > 480
      root.$store.dispatch(SET_IS_SP, isSp)
      root.$store.dispatch(SET_IS_TABLET, isTablet)
      nowWidth.value = window.innerWidth
    })

    const isSp = computed(() => root.$store.getters.isSp)
    const showSidebar = computed(() =>
      isSp.value && root.$store.getters.selectedChat
        ? false
        : root.$route.meta.showSideBar
    )

    window.onresize = () => {
      if (
        (window.innerWidth <= 991 && nowWidth.value > 991) ||
        (window.innerWidth > 991 && nowWidth.value <= 991)
      ) {
        console.log('window onresize')
        loaded.value = false
        location.reload()
      }
    }

    return {
      loaded,
      showSidebar,
      show404,
      isSp,
      useScroll
    }
  }
})
</script>
