<template src="./ChatView.html" />
<style lang="scss" scoped src="./ChatView.scss"></style>
<script>
import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import {
  CHAT_STATUS,
  CHAT_TYPE,
  FOCUSED_CHAT,
  LOADING_TYPE_ATTACH,
  LOADING_TYPE_NONE,
  TAG_ID,
  USER_CATEGORY
} from '@/constants/constant'
import {
  ADD_MESSAGE,
  EDIT_MESSAGE,
  FETCH_CHATS,
  POST_MESSAGE,
  PUT_MESSAGE,
  SET_LOADING
} from '@/store/types'
import useMe from '@/comporsables/useMe'
import { getNowFullString, getNow } from '@/utils/date'
import Messages from '@/compornents/Messages/Messages'
import Camera from '@/compornents/Camera'
import { putS3Attachment } from '@/comporsables/useAws'
import { canPreview, getExt, iconExists, replace_all } from '@/utils/common'
import { createImage, isImage, toBlob } from '@/utils/image'
import { getWindowHeight } from '@/utils/window'

export default defineComponent({
  name: 'ChatView',
  components: {
    Messages,
    Camera
  },
  props: {
    isFamily: {
      type: Boolean,
      require: true
    },
    focusedChat: {
      type: String,
      require: true
    },
    selectedChat: {
      type: Object,
      require: true
    },
    message_type: {
      type: String,
      require: true
    },
    makeReportMode: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit, root }) {
    const isSp = computed(() => root.$store.getters.isSp)
    const inputMessage = ref('')
    const charHeight = isSp.value ? 20 : 24
    const lineBreakCount = computed(() => inputMessage.value.split('\n').length)
    const lineBreakCountByChar = computed(() =>
      inputMessage.value.length > (isSp.value ? 16 : 26)
        ? inputMessage.value.length / (isSp.value ? 16 : 26)
        : 1
    )
    const messageHeight = computed(() => {
      const lineBreak =
        lineBreakCount.value > lineBreakCountByChar.value
          ? lineBreakCount.value
          : lineBreakCountByChar.value
      return (lineBreak > 20 ? 20 : lineBreak) * charHeight + 30
    })

    const { me } = useMe(root)
    const messages = computed(() =>
      props.isFamily ||
      (props.selectedChat.chat_type === CHAT_TYPE.GROUP &&
        me.category_id === USER_CATEGORY.FAMILY)
        ? root.$store.getters.family_messages
        : root.$store.getters.messages
    )
    const showFamilyChat = computed(() => root.$store.getters.show_family_chat)

    const scrollToBottom = () => {
      let container = props.isFamily
        ? '#familyMessageViewContainer > .divScroll'
        : '#messageViewContainer > .divScroll'

      if (isSp.value) {
        container = props.isFamily
          ? '#familyMessageViewContainer'
          : '#messageViewContainer'
      }

      root.$scrollTo('#bottom', 0, {
        container: container,
        force: true,
        offset: 0,
        x: false,
        y: true
      })
    }

    watch(
      // 別のチャット選択したらクリアする
      () => props.selectedChat,
      () => {
        closeReply()
      }
    )

    const textareaRef = ref(null)

    const editMessage = ref('')
    const selectEditMessage = message_id => {
      quoteMessage.value = ''
      replyMessage.value = ''
      editMessage.value = messages.value.find(m => m.message_id === message_id)
      inputMessage.value = editMessage.value.message
      emit(
        'changeFocusedChat',
        props.isFamily ? FOCUSED_CHAT.FAMILY : FOCUSED_CHAT.INDIVIDUAL_OR_GROUP
      )
      textareaRef.value.focus()
    }

    const quoteMessage = ref('')
    const selectQuoteMessage = message_id => {
      editMessage.value = ''
      replyMessage.value = ''
      quoteMessage.value = messages.value.find(m => m.message_id === message_id)
      const tmp = replace_all(quoteMessage.value.message, '\n', '@BR@')
        .replace(/\[引用 (.*)](.*)\[\/引用]/, '')
        .replace(/\[返信 (.*)](.*)\[\/返信]/, '')
      const message = replace_all(tmp, '@BR@', '\n')
      inputMessage.value = `[引用 uid=${quoteMessage.value.user_id},time=${quoteMessage.value.updated_at}]${message}[/引用]\n`
      emit(
        'changeFocusedChat',
        props.isFamily ? FOCUSED_CHAT.FAMILY : FOCUSED_CHAT.INDIVIDUAL_OR_GROUP
      )
      textareaRef.value.focus()
    }

    const replyMessage = ref('')
    const selectReplyMessage = message_id => {
      editMessage.value = ''
      quoteMessage.value = ''
      replyMessage.value = messages.value.find(m => m.message_id === message_id)
      const tmp = replace_all(replyMessage.value.message, '\n', '@BR@')
        .replace(/\[返信 (.*)](.*)\[\/返信]/, '')
        .replace(/\[引用 (.*)](.*)\[\/引用]/, '')
      const message = replace_all(tmp, '@BR@', '\n')
      inputMessage.value = `[返信 uid=${replyMessage.value.user_id},time=${replyMessage.value.updated_at}]${message}[/返信]\n`
      emit(
        'changeFocusedChat',
        props.isFamily ? FOCUSED_CHAT.FAMILY : FOCUSED_CHAT.INDIVIDUAL_OR_GROUP
      )
      textareaRef.value.focus()
    }

    const closeReply = () => {
      showtagError.value = false
      editMessage.value = ''
      quoteMessage.value = ''
      replyMessage.value = ''
      inputMessage.value = ''
    }

    const validateTags = message => {
      const regexQuote = /\[引用 uid=(.*?),time=(.*?)\]([\s\S]*?)\[\/引用\]/
      const regexReply = /\[返信 uid=(.*?),time=(.*?)\]([\s\S]*?)\[\/返信\]/
      const valQuote = /\[引用/
      const valReply = /\[返信/

      if (regexQuote.test(message)) {
        return regexQuote.exec(message) !== null
      } else if (regexReply.test(message)) {
        return regexReply.exec(message) !== null
      } else if (!valQuote.test(message) && !valReply.test(message)) {
        return true
      } else {
        return false
      }
    }

    const postMessage = () => {
      if (!inputMessage.value) {
        return
      }
      if (showFamilyChat.value && props.focusedChat === '') {
        // ファミリーチャットを開いている時はチャットにフォーカスしていないとメッセージ送信できない
        return
      }
      const message = inputMessage.value
      const postAttachFile = attachFile.value

      quoteMessage.value = ''
      replyMessage.value = ''
      inputMessage.value = ''
      attachFile.value = ''

      let messageParam = {
        message_id: -1,
        chat_id: props.selectedChat.chat_id,
        message: message,
        message_type: props.message_type,
        user_id: me.id,
        first_name: me.first_name,
        last_name: me.last_name,
        profile_color: me.profile_color,
        quote_id: 0,
        reply_id: 0,
        created_at: getNow(),
        updated_at: getNow()
      }
      if (postAttachFile) {
        messageParam = {
          ...messageParam,
          tag_id: selectedTagId.value,
          file_name: fileName.value + '.' + fileExt.value,
          path: attachFileKey.value,
          size: postAttachFile.size
        }
      }
      root.$store.dispatch(ADD_MESSAGE, {
        message: messageParam,
        isFamily: props.focusedChat === 'family'
      })

      let postParam = {
        user_id: me.id,
        chat_id: props.selectedChat.chat_id,
        message_type: props.message_type,
        message: message,
        loading_type: LOADING_TYPE_NONE
      }
      if (postAttachFile) {
        postParam = {
          ...postParam,
          tag_id: parseInt(selectedTagId.value),
          file_name: fileName.value + '.' + fileExt.value,
          path: attachFileKey.value,
          size: postAttachFile.size,
          loading_type: LOADING_TYPE_ATTACH
        }
      }

      root.$store.dispatch(POST_MESSAGE, postParam).then(() => {
        resetFile()
        root.$store.dispatch(FETCH_CHATS, LOADING_TYPE_NONE)
      })
    }

    const putMessage = () => {
      if (inputMessage.value === '') {
        return
      }

      const putMessage = editMessage.value
      editMessage.value = null

      root.$store.dispatch(EDIT_MESSAGE, {
        message: { ...putMessage, message: inputMessage.value },
        isFamily: props.focusedChat === 'family'
      })
      root.$store.dispatch(PUT_MESSAGE, {
        user_id: me.id,
        chat_id: props.selectedChat.chat_id,
        message_id: putMessage.message_id,
        message_type: putMessage.message_type,
        message: inputMessage.value
      })
      inputMessage.value = ''
    }

    const handleInput = () => {
      showtagError.value = false
    }

    const showtagError = ref(false)
    const isDisabled = computed(() => showtagError.value === true)

    const sendMessage = () => {
      const messageToSend = inputMessage.value.trim()
      if (!validateTags(messageToSend)) {
        showtagError.value = true
      } else {
        if (editMessage.value) {
          putMessage()
        } else {
          postMessage()
          scrollToBottom()
        }
      }
    }

    const openDeleteMessageConfirm = message_id => {
      emit('openDeleteMessageConfirm', message_id)
    }

    const fetchNextMessages = () => {
      emit('fetchNextMessages', props.isFamily)
    }

    const changeFocusedChat = focusedChat => {
      emit('changeFocusedChat', focusedChat)
    }

    const attachFile = ref(null)
    const uploadFileData = ref('')
    const fileName = ref('')
    const fileExt = ref('')
    const inputFile = e => {
      const files = e.target.files || e.dataTransfer.files
      attachFile.value = files[0]
      fileExt.value = getExt(attachFile.value.name)
      fileName.value = attachFile.value.name.split(`.${fileExt.value}`)[0]
      showCameraMenu.value = false
      if (isImage(fileExt.value)) {
        createImage(attachFile.value, uploadFileData)
      }
    }

    const inputFileTag = ref('')
    const resetFile = () => {
      if (!isSp.value) {
        inputFileTag.value = null
      }
      attachFile.value = null
      uploadFileData.value = ''
      fileExt.value = ''
      fileName.value = ''
      selectedTagId.value = ''
    }

    const attachFileKey = ref('')
    const selectedTagId = ref('')
    const selectedTagName = computed(() => {
      const tag = Object.values(TAG_ID).find(
        t => t.value === selectedTagId.value
      )
      return tag ? tag.label : ''
    })
    const showTagList = ref(false)
    const inputTag = v => (selectedTagId.value = v)
    const sendMessageWithAttach = () => {
      root.$store.dispatch(SET_LOADING, {
        type: LOADING_TYPE_ATTACH,
        status: true
      })

      attachFileKey.value = `chat-${props.selectedChat.chat_id}/${
        props.selectedChat.chat_type
      }/${getNowFullString()}_${me.id}_${attachFile.value.name}`

      putS3Attachment(
        attachFileKey.value,
        isImage(fileExt.value)
          ? toBlob(uploadFileData.value, attachFile.value.type)
          : attachFile.value,
        attachFile.value.type
      )
        .then(() => {
          postMessage()
        })
        .catch(err => {
          console.log(err)
          root.$notify({
            title: '',
            message: 'ファイルのアップロードに失敗しました',
            type: 'error'
          })
        })
        .finally(() => {
          showCameraMenu.value = false
          root.$store.dispatch(SET_LOADING, {
            type: LOADING_TYPE_ATTACH,
            status: false
          })
        })
    }

    const canSendAttach = computed(
      () => selectedTagId.value !== '' && inputMessage.value !== ''
    )
    const canPreviewFile = computed(() => canPreview(attachFile.value.name))

    const loading = computed(() => root.$store.getters.loading)

    const preview = message => {
      emit('preview', message)
    }

    const showCameraMenu = ref(false)

    const chatViewRef = ref(null)

    const isDragEnter = ref(0)
    const dragEnter = () => {
      isDragEnter.value++
    }
    const dragLeave = () => {
      isDragEnter.value--
    }
    const dropFile = e => {
      attachFile.value = e.dataTransfer.files[0]
      fileExt.value = getExt(attachFile.value.name)
      fileName.value = attachFile.value.name.split(`.${fileExt.value}`)[0]
      showCameraMenu.value = false
      if (isImage(fileExt.value)) {
        createImage(attachFile.value, uploadFileData)
      }
      isDragEnter.value = 0
    }

    const handlePasteFile = event => {
      if (event.clipboardData.types[0] === 'Files') {
        attachFile.value = event.clipboardData.items[0].getAsFile()
        fileExt.value = getExt(attachFile.value.name)
        fileName.value = attachFile.value.name.split(`.${fileExt.value}`)[0]
        showCameraMenu.value = false
        if (isImage(fileExt.value)) {
          createImage(attachFile.value, uploadFileData)
        }
      }
    }

    return {
      isSp,
      loading,
      messages,
      messageHeight,
      inputMessage,
      editMessage,
      replyMessage,
      quoteMessage,
      textareaRef,
      validateTags,
      showtagError,
      handleInput,
      isDisabled,
      FOCUSED_CHAT,
      TAG_ID,
      CHAT_STATUS,
      CHAT_TYPE,
      closeReply,
      sendMessage,
      sendMessageWithAttach,
      selectEditMessage,
      selectQuoteMessage,
      selectReplyMessage,
      openDeleteMessageConfirm,
      fetchNextMessages,
      changeFocusedChat,
      inputFile,
      fileName,
      fileExt,
      attachFile,
      selectedTagId,
      selectedTagName,
      inputTag,
      uploadFileData,
      inputFileTag,
      resetFile,
      canSendAttach,
      preview,
      canPreviewFile,
      iconExists,
      showCameraMenu,
      showTagList,
      dropFile,
      dragEnter,
      dragLeave,
      isDragEnter,
      handlePasteFile,
      chatViewRef,
      windowHeight: getWindowHeight(67)
    }
  }
})
</script>
