<template src="./Platinum.html" />
<style lang="scss" scoped src="./Platinum.scss"></style>
<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import useMe from '@/comporsables/useMe'
import HeaderRight from '@/compornents/HeaderRight/HeaderRight'
import AvatarImg from '@/compornents/AvatarImg/AvatarImg'
import PlatinumMenu from '@/compornents/PlatinumMenu/PlatinumMenu'
import Office from '@/compornents/Office/Office'
import { getWindowHeight } from '@/utils/window'
import { REGIST_PLATINUM, CANCELL_PLATINUM, FETCH_OFFICE } from '@/store/types'
export default defineComponent({
  name: 'Platinum',
  title() {
    return 'プラチナ会員'
  },
  components: {
    HeaderRight,
    AvatarImg,
    Office,
    PlatinumMenu
  },
  setup(_, { root }) {
    const { me } = useMe(root)
    const isManage = computed(() => root.$store.getters.isManage)
    const office = computed(() => root.$store.getters.office)
    if (!isManage.value) {
      root.$router.push({ name: 'home' })
    }
    const isError = ref(false)
    const registPlatinum = () => {
      root.$store
        .dispatch(REGIST_PLATINUM)
        .then(() => {
          return root.$store.dispatch(FETCH_OFFICE)
        })
        .then(() => {
          root.$notify({
            title: '',
            message:
              'プラチナ会員登録を行いました。<br>プラチナページ編集から情報を登録して下さい。',
            dangerouslyUseHTMLString: true,
            type: 'success'
          })
        })
        .catch(error => {
          isError.value = true
          console.error('プラチナ会員申請が失敗しました。エラー:', error)
          root.$notify({
            title: '',
            message: 'プラチナ会員申請が失敗しました。',
            type: 'error'
          })
        })
    }
    const CancellPlatinum = () => {
      root.$store
        .dispatch(CANCELL_PLATINUM)
        .then(() => {
          root.$notify({
            title: '',
            message:
              'プラチナ会員退会申請を行いました。<br>プラチナ会員へ変更時、ご登録のメールアドレスへプラチナ会員退会の通知をいたします。',
            dangerouslyUseHTMLString: true,
            type: 'success'
          })
        })
        .catch(error => {
          isError.value = true
          console.error('プラチナ会員退会申請が失敗しました。エラー:', error)
          root.$notify({
            title: '',
            message: 'プラチナ会員退会申請が失敗しました。',
            type: 'error'
          })
        })
    }

    return {
      loading: computed(() => root.$store.getters.loading),
      isSp: computed(() => root.$store.getters.isSp),
      me,
      office,
      isError,
      registPlatinum,
      CancellPlatinum,
      windowHeight: getWindowHeight(74)
    }
  }
})
</script>
