<template src="./MapMenu.html" />
<style lang="scss" scoped src="./MapMenu.scss"></style>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import HeaderRight from '@/compornents/HeaderRight/HeaderRight'
import AvatarImg from '@/compornents/AvatarImg/AvatarImg'
import { getWindowHeight } from '@/utils/window'
import ManageMenu from '@/compornents/ManageMenu/ManageMenu'
import { MAP_ON, MAP_OFF } from '@/store/types'

export default defineComponent({
  name: 'MapMenu',
  title() {
    return '地図表示設定'
  },
  components: {
    HeaderRight,
    AvatarImg,
    ManageMenu
  },
  setup(_, { root }) {
    const offices = computed(() => root.$store.getters.office)

    const mapon = () => {
      root.$store
        .dispatch(MAP_ON, offices.value.user_id)
        .then(() => location.reload())
    }
    const mapoff = () => {
      root.$store
        .dispatch(MAP_OFF, offices.value.user_id)
        .then(() => location.reload())
    }

    return {
      loading: computed(() => root.$store.getters.loading),
      isSp: computed(() => root.$store.getters.isSp),
      offices,
      mapon,
      mapoff,
      windowHeight: getWindowHeight(74)
    }
  }
})
</script>
