<template src="./AddContactModal.html" />
<style lang="scss" scoped src="./AddContactModal.scss"></style>
<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import {
  CLEAR_USER_SEARCH_RESULT,
  FETCH_CONTACTS,
  GET_USER_SEARCH,
  POST_CONTACT
} from '@/store/types'
import useOffice from '@/comporsables/useOffice'
import ProfileView from '@/compornents/ProfileView/ProfileView'
import ProfileModal from '@/compornents/ProfileModal/ProfileModal'
import AvatarImg from '@/compornents/AvatarImg/AvatarImg'

export default defineComponent({
  name: 'AddContactModal',
  components: {
    ProfileView,
    ProfileModal,
    AvatarImg
  },
  props: {
    showAddContactModal: {
      type: Boolean,
      require: true
    }
  },
  setup(props, { emit, root }) {
    const isSp = computed(() => root.$store.getters.isSp)
    const min_care_id = ref('')
    const user = computed(() => root.$store.getters.user)
    const { office, updateOfficeData } = useOffice(root)
    const searched = ref(false)
    const showProfileModal = ref(false)
    const postSearch = () => {
      if (min_care_id.value === '') {
        return
      }
      root.$store.dispatch(GET_USER_SEARCH, min_care_id.value).then(() => {
        updateOfficeData(user.value) // 検索したユーザのofficeを取得
        searched.value = true
      })
    }

    const deleteMinCareId = () => {
      min_care_id.value = ''
      searched.value = false
      root.$store.dispatch(CLEAR_USER_SEARCH_RESULT)
    }

    const showConfirm = ref(false)
    const addContactConfirm = () => {
      showConfirm.value = true
    }
    const postContact = () => {
      root.$store
        .dispatch(POST_CONTACT, { user_id: user.value.id })
        .then(() => {
          root.$store.dispatch(FETCH_CONTACTS)
          root.$store.dispatch(CLEAR_USER_SEARCH_RESULT)
          showConfirm.value = false
          min_care_id.value = ''
          emit('closeAddContentModal')
        })
    }

    return {
      loading: computed(() => root.$store.getters.loading),
      isSp,
      min_care_id,
      user,
      office,
      showConfirm,
      searched,
      deleteMinCareId,
      postSearch,
      addContactConfirm,
      postContact,
      showProfileModal
    }
  }
})
</script>
