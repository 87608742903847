<template src="./ManagePr.html" />
<style lang="scss" scoped src="./ManagePr.scss"></style>
<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import HeaderRight from '@/compornents/HeaderRight/HeaderRight'
import AvatarImg from '@/compornents/AvatarImg/AvatarImg'
import ManageMenu from '@/compornents/ManageMenu/ManageMenu'
import { getWindowHeight } from '@/utils/window'
import { FETCH_PR, POST_PR, MAP_ON, MAP_OFF } from '@/store/types'

export default defineComponent({
  name: 'ManagePr',
  title() {
    return 'PR設定 - 介護事業者管理画面'
  },
  components: {
    HeaderRight,
    AvatarImg,
    ManageMenu
  },
  setup(_, { root }) {
    const isSp = computed(() => root.$store.getters.isSp)
    const isManage = computed(() => root.$store.getters.isManage)
    const isManager = computed(() => root.$store.getters.isManager)
    const pr = ref('')

    const fetchPr = async () => {
      try {
        await root.$store.dispatch(FETCH_PR)
        pr.value = root.$store.getters.pr
      } catch (error) {
        console.error('Failed to fetch platinum data:', error)
      }
    }
    fetchPr()

    const office = computed(() => root.$store.getters.office)

    // SPまたは事業管理者以外の場合はホームへ
    if (isSp.value || !isManage.value) {
      root.$router.push({ name: 'home' })
    }

    const checked = computed(() => office.value.map == '0')

    const updateMap = () => {
      if (office.value.map == '0') {
        mapoff()
      } else {
        mapon()
      }
    }

    const postPr = () => {
      root.$store
        .dispatch(POST_PR, { pr: pr.value })
        .then(() => {
          root.$notify({
            title: '',
            message: 'PR文を登録しました',
            type: 'success'
          })
        })
        .catch(() => {
          pr.value = ''
          root.$store.dispatch(FETCH_PR)
        })
    }

    const mapon = () => {
      root.$store
        .dispatch(MAP_ON, office.value.user_id)
        .then(() => location.reload())
    }
    const mapoff = () => {
      root.$store
        .dispatch(MAP_OFF, office.value.user_id)
        .then(() => location.reload())
    }

    return {
      loading: computed(() => root.$store.getters.loading),
      isManager,
      postPr,
      fetchPr,
      checked,
      updateMap,
      pr,
      mapon,
      mapoff,
      windowHeight: getWindowHeight(74)
    }
  }
})
</script>
