import * as http from '@/utils/api'
import {
  FETCH_MANAGE_REGISTER_OFFICE,
  POST_MANAGE_REGISTER_OFFICE
} from './types'
import { ENDPOINT_MANAGE_REGISTER_OFFICE } from '@/constants/endpoint'
import { LOADING_TYPE_MANAGE_REGISTER_OFFICE } from '@/constants/constant'
import { isEmptyObject } from '@/utils/common'

export default {
  state: {
    manage_regisuter_office: null
  },
  mutations: {
    [FETCH_MANAGE_REGISTER_OFFICE](state, responseData) {
      if (!isEmptyObject(responseData)) {
        state.manage_regisuter_office = responseData
        console.log(responseData)
      }
    }
  },
  getters: {
    company: state => state.manage_regisuter_office
  },
  actions: {
    [FETCH_MANAGE_REGISTER_OFFICE]: async ({ commit, dispatch }) => {
      await http
        .get(
          ENDPOINT_MANAGE_REGISTER_OFFICE,
          {},
          LOADING_TYPE_MANAGE_REGISTER_OFFICE
        )
        .then(response => {
          commit(FETCH_MANAGE_REGISTER_OFFICE, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
        })
    },
    [POST_MANAGE_REGISTER_OFFICE]: async ({ dispatch }, params) => {
      await http
        .post(
          ENDPOINT_MANAGE_REGISTER_OFFICE,
          params,
          LOADING_TYPE_MANAGE_REGISTER_OFFICE
        )
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    }
  }
}
