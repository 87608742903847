<template src="./ManageRegisterOffice.html" />
<style lang="scss" scoped src="./ManageRegisterOffice.scss"></style>
<script>
import {
  computed,
  defineComponent,
  reactive,
  ref,
  watch
} from '@vue/composition-api'
import useMe from '@/comporsables/useMe'
import {
  COPY_RIGHT,
  PAYMENTS,
  PREFECTURE_LIST,
  OFFICE_CATEGORY,
  OFFICE_CATEGORY_NAMES,
  SUB_CATEGORY,
  ID_CHECKED_STATUS,
  LOADING_TYPE_ATTACH
} from '@/constants/constant'
import {
  GET_POST_CODE,
  POST_MANAGE_REGISTER_OFFICE,
  FETCH_ME,
  GET_USER_SEARCH_2,
  GET_OFFICE_SEARCH,
  GET_OTHER_OFFICE_SEARCH,
  GET_KAIGO_KOUHYOU,
  POST_OFFICE_IDENTIFICATION2,
  SET_LOADING
} from '@/store/types'
import HeaderRight from '@/compornents/HeaderRight/HeaderRight'
import ManageMenu from '@/compornents/ManageMenu/ManageMenu'
import AvatarImg from '@/compornents/AvatarImg/AvatarImg'
import Camera from '@/compornents/Camera'
import { canPreview, getExt } from '@/utils/common'
import { createImage, isImage, toBlob } from '@/utils/image'
import { getNowFullString } from '@/utils/date'
import { putS3Attachment } from '@/comporsables/useAws'

export default defineComponent({
  name: 'ManageRegisterOffice',
  title() {
    return '事業新規登録'
  },
  components: {
    HeaderRight,
    ManageMenu,
    AvatarImg,
    Camera
  },
  props: {
    isEdit: {
      type: Boolean
    }
  },
  setup(props, { root }) {
    const isSp = computed(() => root.$store.getters.isSp)
    const { me } = useMe(root)
    root.$store.dispatch(FETCH_ME).then(() => {
      if (!me.is_login) {
        root.$router.push({ name: 'login' })
      }
    })
    const isManage = computed(() => root.$store.getters.isManage)
    const kaigo_kouhyou = computed(() => root.$store.getters.kaigo_kouhyou)
    const step = ref(1)
    const isRepresentative = computed(
      () => root.$store.getters.isRepresentative
    )

    // SPまたは事業管理者以外の場合はホームへ
    if (isSp.value || (!isManage.value && !isRepresentative.value)) {
      root.$router.push({ name: 'home' })
    }

    const form = reactive({
      category_id: '',
      sub_category_id: '',
      other_sub_category_name: '',
      office_number: '',
      other_office_number: '',
      company_name: '',
      company_name_kana: '',
      min_care_id: '',
      delegation_last_name: '',
      delegation_last_name_kana: '',
      delegation_first_name_kana: '',
      postcode: '',
      prefecture_id: '',
      city: '',
      block: '',
      building: '',
      branch_name: '',
      personal_last_name: '',
      personal_last_name_kana: '',
      personal_first_name: '',
      personal_first_name_kana: '',
      office_phone: '',
      fax_phone: '',
      mobile_phone: '',
      email: '',
      url1: '',
      url2: '',
      url3: '',
      payment: ''
    })
    const post = () => {
      let param = {
        category_id: form.category_id,
        sub_category_id: form.sub_category_id,
        other_sub_category_name: form.other_sub_category_name,
        office_number: form.office_number,
        other_office_number: form.other_office_number,
        company_name: form.company_name,
        company_name_kana: form.company_name_kana,
        min_care_id: form.min_care_id,
        delegation_last_name: form.delegation_last_name,
        delegation_last_name_kana: form.delegation_last_name_kana,
        delegation_first_name: form.delegation_first_name,
        delegation_first_name_kana: form.delegation_first_name_kana,
        postcode: form.postcode,
        prefecture_id: form.prefecture_id,
        city: form.city,
        block: form.block,
        building: form.building,
        branch_name: form.branch_name,
        personal_last_name: form.personal_last_name,
        personal_last_name_kana: form.personal_last_name_kana,
        personal_first_name: form.personal_first_name,
        personal_first_name_kana: form.personal_first_name_kana,
        office_phone: form.office_phone,
        fax_phone: form.fax_phone,
        mobile_phone: form.mobile_phone,
        email: form.email,
        url1: form.url1,
        url2: form.url2,
        url3: form.url3,
        payment: form.payment
      }
      root.$store
        .dispatch(POST_MANAGE_REGISTER_OFFICE, param)
        .then(() => {
          root.$notify({
            title: '',
            message: '新規事業登録が完了しました',
            dangerouslyUseHTMLString: true,
            type: 'success'
          })
          root.$router.push({ name: 'manage' })
        })
        .catch(() => undefined)
    }

    const minCareIdValid = ref('')

    watch(
      () => form.min_care_id,
      v => {
        if (v.length < 6) {
          return
        }
        minCareIdValid.value = ''
        root.$store
          .dispatch(GET_USER_SEARCH_2, form.min_care_id)
          .then(() => {
            minCareIdValid.value = root.$store.getters.user ? 'OK' : 'NG'
            console.log(root.$store.getters.user)
          })
          .catch(() => {
            minCareIdValid.value = 'NG'
          })
      }
    )

    const officeNumberValid = ref('')
    const office = ref('')
    watch(
      () => form.office_number,
      v => {
        if (v.length < 9) {
          return
        }
        officeNumberValid.value = ''
        office.value = ''
        root.$store
          .dispatch(GET_OFFICE_SEARCH, form.office_number)
          .then(() => {
            office.value = root.$store.getters.serchOffice
            officeNumberValid.value =
              Object.keys(office.value).length > 0 ? 'NG' : 'OK'
          })
          .catch(() => {
            officeNumberValid.value = 'NG'
          })
      }
    )

    const otherOfficeNumberValid = ref('')
    const otherOffice = ref('')
    watch(
      () => form.other_office_number,
      v => {
        if (v.length < 11) {
          return
        }
        otherOfficeNumberValid.value = ''
        otherOffice.value = ''
        root.$store
          .dispatch(GET_OTHER_OFFICE_SEARCH, form.other_office_number)
          .then(() => {
            otherOffice.value = root.$store.getters.serchOtherOffice
            otherOfficeNumberValid.value =
              Object.keys(otherOffice.value).length > 0 ? 'NG' : 'OK'
          })
          .catch(() => {
            otherOfficeNumberValid.value = 'NG'
          })
      }
    )

    const postCodeIsInvalid = ref(false)
    const selectedPrefecture = computed(() =>
      form.prefecture_id
        ? PREFECTURE_LIST.find(p => p.value === parseInt(form.prefecture_id))
            .label
        : ''
    )

    watch(
      () => form.postcode,
      v =>
        root.$store
          .dispatch(GET_POST_CODE, v)
          .then(() => {
            postCodeIsInvalid.value = false
            if (!autojiscode.value) {
              form.prefecture_id = root.$store.getters.address.prefecture_id
              form.city = root.$store.getters.address.city
              form.block = root.$store.getters.address.block
            }
          })
          .catch(() => {
            postCodeIsInvalid.value = true
          })
    )
    const autojiscode = ref(false)

    watch(
      () => form.office_number,
      v => {
        root.$store
          .dispatch(GET_KAIGO_KOUHYOU, v)
          .then(() => {
            form.office_number = v
            const prefCode = root.$store.getters.kaigo_kouhyou.pref
            const prefecture = PREFECTURE_LIST.find(
              item => item.label === prefCode
            )
            if (prefecture) {
              form.prefecture_id = prefecture.value
            }
            form.postcode = root.$store.getters.kaigo_kouhyou.zipcode
            form.company_name = root.$store.getters.kaigo_kouhyou.company_name
            form.company_name_kana =
              root.$store.getters.kaigo_kouhyou.company_name_kana
            form.city = root.$store.getters.kaigo_kouhyou.city
            form.block = root.$store.getters.kaigo_kouhyou.address
            form.building = root.$store.getters.kaigo_kouhyou.building
            form.office_phone = root.$store.getters.kaigo_kouhyou.tel
            form.homepage_url = root.$store.getters.kaigo_kouhyou.url
            autojiscode.value = true
          })
          .catch(() => {
            console.error('Error fetching')
          })
      }
    )

    const selectedPayment = computed(
      () =>
        // form.payment
        //   ? PAYMENTS.find(p => p.value === form.payment + '').label
        //   : ''
        PAYMENTS.find(p => p.value === form.payment + '').label
    )

    const loading = computed(() => root.$store.getters.loading)

    const next = () => {
      step.value = 2
      window.scroll(0, 0)
    }

    const next2 = () => {
      step.value = 3
      window.scroll(0, 0)
    }

    const prev = () => {
      step.value = 1
      window.scroll(0, 0)
    }

    const prev2 = () => {
      step.value = 2
      window.scroll(0, 0)
    }

    const selectCategory = category_id => {
      form.category_id = category_id
      form.sub_category_id = ''
      form.other_sub_category_name = ''
    }

    const showSubCategoryError = ref(false)

    const showCategoryError = ref(false)

    const categoryName = computed(() => OFFICE_CATEGORY_NAMES[form.category_id])
    const subCategoryName = computed(() => {
      if (form.category_id === OFFICE_CATEGORY.BUSINESS) {
        return SUB_CATEGORY.BUSINESS.find(c => c.value === form.sub_category_id)
          .label
      }
      if (form.category_id === OFFICE_CATEGORY.MEDICAL) {
        return SUB_CATEGORY.MEDICAL.find(c => c.value === form.sub_category_id)
          .label
      }
      if (form.category_id === OFFICE_CATEGORY.OTHER) {
        return form.other_sub_category_name
      }
    })

    const showInvalidErrorMsg = ref(false)

    const showCameraMenu = ref(false)
    const showCameraNo = ref(0)
    const attachFile1 = ref('')
    const uploadFileData1 = ref('')
    const fileRef1 = ref(null)
    const inputFile = e => {
      const files = e.target.files || e.dataTransfer.files
      if (!isImage(getExt(files[0].name))) {
        root.$notify({
          title: '',
          message: '画像ファイルを選択してください',
          type: 'error'
        })
        switch (showCameraNo.value) {
          case 1:
            fileRef1.value.value = null
            break
        }
        return
      }
      showCameraMenu.value = false
      switch (showCameraNo.value) {
        case 1:
          attachFile1.value = files[0]
          createImage(attachFile1.value, uploadFileData1)
          break
      }
    }
    const canPreviewFile = no => {
      switch (no) {
        case 1:
          return attachFile1.value ? canPreview(attachFile1.value.name) : false
      }
    }

    const canUpload = computed(() => uploadFileData1.value)
    const upload = () => {
      root.$store.dispatch(SET_LOADING, {
        type: LOADING_TYPE_ATTACH,
        status: true
      })

      const attachFileKey1 = `officeidentification-${
        me.id
      }/${getNowFullString()}_${attachFile1.value.name}`

      const officeNumber = form.office_number || form.other_office_number

      Promise.all([
        putS3Attachment(
          attachFileKey1,
          toBlob(uploadFileData1.value, attachFile1.value.type),
          attachFile1.value.type
        )
      ])
        .then(() => {
          root.$store
            .dispatch(POST_OFFICE_IDENTIFICATION2, {
              identity_image_front_side_url: attachFileKey1,
              company_name: form.company_name,
              office_number: officeNumber,
              min_care_id: form.min_care_id
            })
            .then(() => {
              showCameraMenu.value = false
              root.$store.dispatch(SET_LOADING, {
                type: LOADING_TYPE_ATTACH,
                status: false
              })
              root.$notify({
                title: '',
                message: '事業所許認可証をアップロードしました',
                type: 'success'
              })
            })
        })
        .catch(err => {
          console.log(err)
          root.$notify({
            title: '',
            message: 'ファイルのアップロードに失敗しました',
            type: 'error'
          })
        })
    }

    const clearFile = no => {
      switch (no) {
        case 1:
          attachFile1.value = ''
          uploadFileData1.value = ''
          fileRef1.value.value = null
          break
      }
    }

    return {
      loading,
      isSp,
      PREFECTURE_LIST,
      step,
      form,
      postCodeIsInvalid,
      selectedPrefecture,
      PAYMENTS,
      selectedPayment,
      COPY_RIGHT,
      next,
      prev,
      next2,
      prev2,
      showInvalidErrorMsg,
      minCareIdValid,
      officeNumberValid,
      otherOfficeNumberValid,
      post,
      categoryName,
      subCategoryName,
      OFFICE_CATEGORY,
      SUB_CATEGORY,
      selectCategory,
      showSubCategoryError,
      showCategoryError,
      autojiscode,
      kaigo_kouhyou,
      ID_CHECKED_STATUS,
      canPreviewFile,
      showCameraNo,
      inputFile,
      uploadFileData1,
      showCameraMenu,
      attachFile1,
      upload,
      canUpload,
      clearFile,
      fileRef1
    }
  }
})
</script>
