import * as http from '@/utils/api'
import {
  GET_OFFICE_IDENTIFICATION,
  POST_OFFICE_IDENTIFICATION,
  POST_OFFICE_IDENTIFICATION2
} from './types'
import {
  ENDPOINT_OFFICE_IDENTIFICATION,
  ENDPOINT_OFFICE_IDENTIFICATION2
} from '@/constants/endpoint'
import { isEmptyObject } from '@/utils/common'
import { ID_CHECKED_STATUS } from '@/constants/constant'

export default {
  state: {
    officeIdentification: null
  },
  mutations: {
    [GET_OFFICE_IDENTIFICATION](state, responseData) {
      if (!isEmptyObject(responseData)) {
        state.officeIdentification = responseData
      }
    }
  },
  getters: {
    officeIdentificationStatus: state =>
      state.officeIdentification
        ? state.officeIdentification.status
        : ID_CHECKED_STATUS.UNAUTHENTICATED,
    officeIdentificationDenialReasonText: state =>
      state.officeIdentification
        ? state.officeIdentification.denial_reason_text
        : ''
  },
  actions: {
    [GET_OFFICE_IDENTIFICATION]: async ({ commit, dispatch }, params) => {
      await http
        .get(ENDPOINT_OFFICE_IDENTIFICATION, params)
        .then(response => {
          commit(GET_OFFICE_IDENTIFICATION, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [POST_OFFICE_IDENTIFICATION]: async ({ dispatch }, params) => {
      await http.post(ENDPOINT_OFFICE_IDENTIFICATION, params).catch(error => {
        http.handleError(dispatch, error)
        throw new Error()
      })
    },

    [POST_OFFICE_IDENTIFICATION2]: async ({ dispatch }, params) => {
      await http.post(ENDPOINT_OFFICE_IDENTIFICATION2, params).catch(error => {
        http.handleError(dispatch, error)
        throw new Error()
      })
    }
  }
}
