import Vue from 'vue'
import Vuex from 'vuex'
import loading from './loading'
import me from './me'
import user from './user'
import message from './message'
import messages from './messages'
import contact from './contact'
import chat from './chat'
import familyChat from './familyChat'
import report from './report'
import records from './records'
import files from './files'
import forgotPassword from './forgotPassword'
import postCode from './postCode'
import schedule from './schedule'
import office from './office'
import officeIdentification from './officeIdentification'
import staff from './staff'
import pr from './pr'
import identification from './identification'
import kaigoKouhyou from './kaigoKouhyou'
import businessPlatinum from './businessPlatinum'
import sample from './sample'
import company from './company'
import manage_register_office from './manage_register_office'
import { isLocal } from '@/utils/common'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    loading,
    me,
    user,
    message,
    messages,
    contact,
    chat,
    familyChat,
    report,
    records,
    files,
    forgotPassword,
    postCode,
    schedule,
    office,
    staff,
    identification,
    kaigoKouhyou,
    officeIdentification,
    pr,
    sample,
    businessPlatinum,
    company,
    manage_register_office
  },
  strict: isLocal
})
