import * as http from '@/utils/api'
import {
  GET_PLATINUM,
  GET_TMP_PLATINUM,
  POST_TMP_PLATINUM,
  UPLODE_TMP_IMG,
  GET_OPTIONS,
  GET_IMGS,
  GET_TMP_OPTIONS,
  GET_TMP_IMGS
} from './types'
import {
  ENDPOINT_BUSINESS_PLATINUM,
  ENDPOINT_POST_TMP_PLATINUM,
  ENDPOINT_UPLODE_TMP_IMG,
  ENDPOINT_BUSINESS_TMP_PLATINUM,
  ENDPOINT_GET_TMP_OPTIONS,
  ENDPOINT_GET_TMP_IMGS,
  ENDPOINT_GET_OPTIONS,
  ENDPOINT_GET_IMGS
} from '@/constants/endpoint'
import { isEmptyObject } from '@/utils/common'
import { LOADING_TYPE_OFFICE } from '@/constants/constant'

export default {
  state: {
    businessPlatinum: null,
    options: null,
    imgs: null
  },
  mutations: {
    [GET_PLATINUM](state, responseData) {
      if (!isEmptyObject(responseData)) {
        state.businessPlatinum = responseData
      }
    },
    [GET_OPTIONS](state, responseData) {
      if (!isEmptyObject(responseData)) {
        state.options = responseData
      }
    },
    [GET_IMGS](state, responseData) {
      if (!isEmptyObject(responseData)) {
        state.imgs = responseData
      }
    },
    [GET_TMP_PLATINUM](state, responseData) {
      if (!isEmptyObject(responseData)) {
        state.businessPlatinum = responseData
      }
    },
    [GET_TMP_OPTIONS](state, responseData) {
      if (!isEmptyObject(responseData)) {
        state.options = responseData
      }
    },
    [GET_TMP_IMGS](state, responseData) {
      if (!isEmptyObject(responseData)) {
        state.imgs = responseData
      }
    }
  },
  getters: {
    isPlatinum: state => state.businessPlatinum,
    isOptions: state => state.options,
    isImgs: state => state.imgs,
    isTmpPlatinum: state => state.businessPlatinum,
    isTmpOptions: state => state.options,
    isTmpImgs: state => state.imgs
  },
  actions: {
    [GET_PLATINUM]: async ({ commit, dispatch }) => {
      await http
        .get(ENDPOINT_BUSINESS_PLATINUM, {}, LOADING_TYPE_OFFICE)
        .then(response => {
          commit(GET_PLATINUM, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [GET_OPTIONS]: async ({ commit, dispatch }) => {
      await http
        .get(ENDPOINT_GET_OPTIONS, {}, LOADING_TYPE_OFFICE)
        .then(response => {
          commit(GET_OPTIONS, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [GET_IMGS]: async ({ commit, dispatch }) => {
      await http
        .get(ENDPOINT_GET_IMGS, {}, LOADING_TYPE_OFFICE)
        .then(response => {
          commit(GET_IMGS, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [GET_TMP_PLATINUM]: async ({ commit, dispatch }) => {
      await http
        .get(ENDPOINT_BUSINESS_TMP_PLATINUM, {}, LOADING_TYPE_OFFICE)
        .then(response => {
          commit(GET_PLATINUM, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [GET_TMP_OPTIONS]: async ({ commit, dispatch }) => {
      await http
        .get(ENDPOINT_GET_TMP_OPTIONS, {}, LOADING_TYPE_OFFICE)
        .then(response => {
          commit(GET_OPTIONS, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [GET_TMP_IMGS]: async ({ commit, dispatch }) => {
      await http
        .get(ENDPOINT_GET_TMP_IMGS, {}, LOADING_TYPE_OFFICE)
        .then(response => {
          commit(GET_IMGS, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [POST_TMP_PLATINUM]: async ({ dispatch }, params) => {
      await http
        .post(ENDPOINT_POST_TMP_PLATINUM, params, LOADING_TYPE_OFFICE)
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [UPLODE_TMP_IMG]: async ({ dispatch }, params) => {
      await http
        .post(ENDPOINT_UPLODE_TMP_IMG, params, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    }
  }
}
