import { ref } from '@vue/composition-api'
import { GET_OFFICE_SEARCH, GET_OTHER_OFFICE_SEARCH } from '@/store/types'
import { OFFICE_CATEGORY_NAMES, SUB_CATEGORY } from '@/constants/constant'

export default function useOffice(root) {
  const office = ref({
    category_id: '',
    category_name: '',
    sub_category_id: '',
    sub_category_name: '',
    other_sub_category_name: ''
  })

  const updateOfficeData = async user => {
    const hasOfficeNumber = !!user.office_number
    const searchType = hasOfficeNumber
      ? GET_OFFICE_SEARCH
      : GET_OTHER_OFFICE_SEARCH
    const officeNumber = hasOfficeNumber
      ? user.office_number
      : user.other_office_number
    const subCategoryType = hasOfficeNumber
      ? SUB_CATEGORY.BUSINESS
      : SUB_CATEGORY.MEDICAL

    console.log('Office function called')

    try {
      const response = await root.$store.dispatch(searchType, officeNumber)
      console.log('Office Search Response:', response.data)

      // APIレスポンスのデータをofficeオブジェクトに設定
      office.value = {
        category_id: response.data.category_id || '',
        sub_category_id: response.data.sub_category_id || '',
        category_name: OFFICE_CATEGORY_NAMES[response.data.category_id] || '',
        sub_category_name:
          subCategoryType.find(
            item => item.value === String(response.data.sub_category_id)
          )?.label || '',
        other_sub_category_name: response.data.other_sub_category_name || ''
      }

      console.log('Updated office object:', office.value)
    } catch (error) {
      console.error('Error during office search:', error)
    }
  }

  return {
    office,
    updateOfficeData
  }
}
