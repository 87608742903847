<template src="./PlatinumPage.html" />
<style lang="scss" scoped src="./PlatinumPage.scss"></style>
<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import HeaderRight from '@/compornents/HeaderRight/HeaderRight'
import AvatarImg from '@/compornents/AvatarImg/AvatarImg'
import PlatinumP from '@/compornents/PlatinumP/PlatinumP'
import useMe from '@/comporsables/useMe'
import { CANCELL_PLATINUM } from '@/store/types'
import { getWindowHeight } from '@/utils/window'
export default defineComponent({
  name: 'PlatinumPage',
  title() {
    return 'その他'
  },
  components: {
    HeaderRight,
    AvatarImg,
    PlatinumP
  },
  setup(_, { root }) {
    const { me } = useMe(root)
    const isManage = computed(() => root.$store.getters.isManage)
    const layer1 = computed(() => root.$route.params.layer1)
    const layer2 = computed(() => root.$route.params.layer2)

    const showModal = ref(false)
    const showMenu = ref(false)

    const kiyakuPlatinum = () => {
      root.$router.push({ name: 'platinum_conditions' })
    }
    const clearShowMenu = () => {
      console.log('clearShowMenu!!')
      showMenu.value = false
    }
    const office = computed(() => root.$store.getters.office)
    if (!isManage.value) {
      root.$router.push({ name: 'home' })
    }
    const isError = ref(false)
    const CancellPlatinum = () => {
      root
        .$confirm('プラチナ会員の解除申請を行いますか。', '確認', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: 'はい',
          cancelButtonText: 'いいえ',
          type: 'warning'
        })
        .then(() => {
          root.$store
            .dispatch(CANCELL_PLATINUM)
            .then(() => {
              root.$notify({
                title: '',
                message:
                  'プラチナ会員解除申請を行いました。<br>プラチナ会員へ変更時、ご登録のメールアドレスへプラチナ会員解除の通知をいたします。',
                dangerouslyUseHTMLString: true,
                type: 'success'
              })
            })
            .catch(error => {
              isError.value = true
              console.error(
                'プラチナ会員解除申請が失敗しました。エラー:',
                error
              )
            })
        })
        .catch(() => {
          root.$notify({
            message: '解除申請をキャンセルしました。',
            type: 'info'
          })
        })
    }

    return {
      loading: computed(() => root.$store.getters.loading),
      isSp: computed(() => root.$store.getters.isSp),
      isManage: computed(() => root.$store.getters.isManage),
      me,
      isError,
      CancellPlatinum,
      layer1,
      layer2,
      showModal,
      clearShowMenu,
      showMenu,
      office,
      kiyakuPlatinum,
      windowHeight: getWindowHeight(74)
    }
  }
})
</script>
