import * as http from '@/utils/api'
import {
  FETCH_OFFICE,
  FETCH_OTHER_OFFICE,
  POST_OFFICE,
  REGIST_PLATINUM,
  CANCELL_PLATINUM,
  MAP_ON,
  MAP_OFF
} from './types'
import {
  ENDPOINT_OFFICE,
  ENDPOINT_OTHER_OFFICE,
  ENDPOINT_MAPON,
  ENDPOINT_MAPOFF,
  ENDPOINT_REGIST_PLATINUM,
  ENDPOINT_CANCELL_PLATINUM
} from '@/constants/endpoint'
import { LOADING_TYPE_OFFICE } from '@/constants/constant'

export default {
  state: {
    office: null,
    otherOffice: null
  },
  mutations: {
    [FETCH_OFFICE](state, responseData) {
      state.office = responseData
    },
    [FETCH_OTHER_OFFICE](state, responseData) {
      state.otherOffice = responseData
    }
  },
  getters: {
    office: state => state.office,
    otherOffice: state => state.otherOffice
  },
  actions: {
    [FETCH_OFFICE]: async ({ commit, dispatch }) => {
      await http
        .get(ENDPOINT_OFFICE, {}, LOADING_TYPE_OFFICE)
        .then(response => {
          commit(FETCH_OFFICE, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
        })
    },
    [FETCH_OTHER_OFFICE]: async ({ commit, dispatch }) => {
      await http
        .get(ENDPOINT_OTHER_OFFICE, {}, LOADING_TYPE_OFFICE)
        .then(response => {
          commit(FETCH_OTHER_OFFICE, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
        })
    },
    [POST_OFFICE]: async ({ dispatch }, params) => {
      await http
        .post(ENDPOINT_OFFICE, params, LOADING_TYPE_OFFICE)
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [MAP_ON]: async ({ dispatch }, params) => {
      await http.get(ENDPOINT_MAPON, params).catch(error => {
        http.handleError(dispatch, error)
        throw new Error()
      })
    },

    [MAP_OFF]: async ({ dispatch }, params) => {
      await http.get(ENDPOINT_MAPOFF, params).catch(error => {
        http.handleError(dispatch, error)
        throw new Error()
      })
    },
    [REGIST_PLATINUM]: async ({ commit, dispatch }) => {
      await http
        .post(ENDPOINT_REGIST_PLATINUM, {}, LOADING_TYPE_OFFICE)
        .then(response => {
          commit(FETCH_OFFICE, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [CANCELL_PLATINUM]: async ({ dispatch }, params) => {
      await http
        .post(ENDPOINT_CANCELL_PLATINUM, params, LOADING_TYPE_OFFICE)
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    }
    // [DELETE_OFFICE]: async ({ dispatch }, params) => {
    //   await http
    //     .del(
    //       ENDPOINT_BUSINESS_PROFILE,
    //       { data: params },
    //       LOADING_TYPE_BUSINESS_PROFILE
    //     )
    //     .catch(error => {
    //       http.handleError(dispatch, error)
    //       throw new Error()
    //     })
    // }
  }
}
