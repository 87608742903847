import * as http from '@/utils/api'
import {
  FETCH_COMPANY,
  POST_COMPANY,
  GET_COMPANY_STAFF,
  CLEAR_COMPANY_STAFF_RESULT
} from './types'
import {
  ENDPOINT_COMPANY,
  ENDPOINT_GET_COMPANY_STAFF
} from '@/constants/endpoint'
import {
  LOADING_TYPE_COMPANY,
  LOADING_TYPE_COMPANY_STAFF
} from '@/constants/constant'
import { isEmptyObject } from '@/utils/common'

export default {
  state: {
    company: null
  },
  mutations: {
    [FETCH_COMPANY](state, responseData) {
      if (!isEmptyObject(responseData)) {
        state.company = responseData
        console.log(responseData)
      }
    }
  },
  getters: {
    company: state => state.company
  },
  actions: {
    [FETCH_COMPANY]: async ({ commit, dispatch }) => {
      await http
        .get(ENDPOINT_COMPANY, {}, LOADING_TYPE_COMPANY)
        .then(response => {
          commit(FETCH_COMPANY, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
        })
    },
    [POST_COMPANY]: async ({ dispatch }, params) => {
      await http
        .post(ENDPOINT_COMPANY, params, LOADING_TYPE_COMPANY)
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [GET_COMPANY_STAFF]: async ({ commit, dispatch }, email) => {
      commit(CLEAR_COMPANY_STAFF_RESULT)
      try {
        const response = await http.get(
          `${ENDPOINT_GET_COMPANY_STAFF}/${email}`,
          {},
          LOADING_TYPE_COMPANY_STAFF
        )
        commit(GET_COMPANY_STAFF, response.data)
        return response
      } catch (error) {
        http.handleError(dispatch, error)
        throw error
      }
    }
  }
}
