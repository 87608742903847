import * as http from '@/utils/api'
import {
  CLEAR_USER_SEARCH_RESULT,
  GET_USER_SEARCH,
  GET_USER_SEARCH_2,
  GET_OFFICE_SEARCH,
  GET_OTHER_OFFICE_SEARCH,
  FETCH_OFFICE,
  FETCH_OTHER_OFFICE,
  PATCH_USER_REGISTER_CONFIRM,
  PATCH_OWNER_REGISTER_CONFIRM,
  POST_USER,
  POST_USER_REGISTER,
  POST_OWNER,
  POST_FAMILY,
  GET_USER_STATUS,
  POST_USER_STATUS,
  CLEAR_USER_STATUS_RESULT
} from './types'
import {
  ENDPOINT_USER,
  ENDPOINT_USER_REGISTER,
  ENDPOINT_USER_REGISTER_CONFIRM,
  ENDPOINT_OWNER_REGISTER_CONFIRM,
  ENDPOINT_USER_SEARCH,
  ENDPOINT_USER_SEARCH_2,
  ENDPOINT_OFFICE_SEARCH,
  ENDPOINT_OTHER_OFFICE_SEARCH,
  ENDPOINT_OWNER_REGISTER,
  ENDPOINT_FAMILY_REGISTER,
  ENDPOINT_GET_USER_STATUS,
  ENDPOINT_POST_USER_STATUS
} from '@/constants/endpoint'
import { isEmptyObject } from '@/utils/common'
import {
  LOADING_TYPE_PAGE,
  LOADING_TYPE_USER_SEARCH,
  LOADING_TYPE_USER_SEARCH_2,
  LOADING_TYPE_USER_STATUS
} from '@/constants/constant'

export default {
  state: {
    user: null,
    serchOffice: null,
    serchOtherOffice: null,
    user_status: null
  },
  mutations: {
    [GET_USER_SEARCH](state, responseData) {
      if (!isEmptyObject(responseData)) {
        state.user = responseData
      }
    },
    [GET_USER_SEARCH_2](state, responseData) {
      if (!isEmptyObject(responseData)) {
        state.user = responseData
      }
    },
    [GET_OFFICE_SEARCH](state, responseData) {
      state.serchOffice = responseData
    },
    [GET_OTHER_OFFICE_SEARCH](state, responseData) {
      state.serchOtherOffice = responseData
    },
    [CLEAR_USER_SEARCH_RESULT](state) {
      state.user = null
    },
    [GET_USER_STATUS](state, responseData) {
      state.user_status = responseData
    },
    [CLEAR_USER_STATUS_RESULT](state) {
      state.user_status = null
    }
  },
  getters: {
    user: state => state.user,
    serchOffice: state => state.serchOffice,
    serchOtherOffice: state => state.serchOtherOffice,
    user_status: state => state.user_status
  },
  actions: {
    [POST_USER_REGISTER]: async ({ dispatch }, params) => {
      await http
        .post(ENDPOINT_USER_REGISTER, params, LOADING_TYPE_PAGE)
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [POST_OWNER]: async ({ dispatch }, params) => {
      await http
        .post(ENDPOINT_OWNER_REGISTER, params, LOADING_TYPE_PAGE)
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [POST_FAMILY]: async ({ dispatch }, params) => {
      await http
        .post(ENDPOINT_FAMILY_REGISTER, params, LOADING_TYPE_PAGE)
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [POST_USER]: async ({ dispatch }, params) => {
      await http.post(ENDPOINT_USER, params, LOADING_TYPE_PAGE).catch(error => {
        http.handleError(dispatch, error)
        throw new Error()
      })
    },
    [PATCH_USER_REGISTER_CONFIRM]: async ({ dispatch }, params) => {
      await http.patch(ENDPOINT_USER_REGISTER_CONFIRM, params).catch(error => {
        http.handleError(dispatch, error)
        throw new Error()
      })
    },
    [PATCH_OWNER_REGISTER_CONFIRM]: async ({ dispatch }, params) => {
      await http.patch(ENDPOINT_OWNER_REGISTER_CONFIRM, params).catch(error => {
        http.handleError(dispatch, error)
        throw new Error()
      })
    },
    [GET_USER_SEARCH]: async ({ commit, dispatch }, min_care_id) => {
      commit(CLEAR_USER_SEARCH_RESULT)
      await http
        .get(
          ENDPOINT_USER_SEARCH + '/' + min_care_id,
          {},
          LOADING_TYPE_USER_SEARCH
        )
        .then(response => {
          commit(GET_USER_SEARCH, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
        })
    },
    [GET_USER_SEARCH_2]: async ({ commit, dispatch }, min_care_id) => {
      commit(CLEAR_USER_SEARCH_RESULT)
      await http
        .get(
          ENDPOINT_USER_SEARCH_2 + '/' + min_care_id,
          {},
          LOADING_TYPE_USER_SEARCH_2
        )
        .then(response => {
          commit(GET_USER_SEARCH_2, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
        })
    },
    [GET_OFFICE_SEARCH]: async ({ commit, dispatch }, office_number) => {
      try {
        const response = await http.get(
          `${ENDPOINT_OFFICE_SEARCH}/${office_number}`,
          {},
          LOADING_TYPE_USER_SEARCH
        )
        commit(GET_OFFICE_SEARCH, response.data)
        commit(FETCH_OFFICE, response.data)
        return response
      } catch (error) {
        http.handleError(dispatch, error)
        throw error
      }
    },
    [GET_OTHER_OFFICE_SEARCH]: async (
      { commit, dispatch },
      other_office_number
    ) => {
      try {
        const response = await http.get(
          `${ENDPOINT_OTHER_OFFICE_SEARCH}/${other_office_number}`,
          {},
          LOADING_TYPE_USER_SEARCH
        )
        commit(GET_OTHER_OFFICE_SEARCH, response.data)
        commit(FETCH_OTHER_OFFICE, response.data)
        return response
      } catch (error) {
        http.handleError(dispatch, error)
        throw error
      }
    },
    [CLEAR_USER_SEARCH_RESULT]({ commit }) {
      commit(CLEAR_USER_SEARCH_RESULT)
    },
    [GET_USER_STATUS]: async ({ commit, dispatch }, user_id) => {
      commit(CLEAR_USER_STATUS_RESULT)
      try {
        const response = await http.get(
          `${ENDPOINT_GET_USER_STATUS}/${user_id}`,
          {},
          LOADING_TYPE_USER_STATUS
        )
        commit(GET_USER_STATUS, response.data)
        return response
      } catch (error) {
        http.handleError(dispatch, error)
        throw error
      }
    },
    [POST_USER_STATUS]: async ({ dispatch }, params) => {
      await http.post(ENDPOINT_POST_USER_STATUS, params).catch(error => {
        http.handleError(dispatch, error)
        throw new Error()
      })
    }
  }
}
