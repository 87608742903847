import { render, staticRenderFns } from "./ManageIdentificationSelect.html?vue&type=template&id=50576022&scoped=true&"
import script from "./ManageIdentificationSelect.vue?vue&type=script&lang=js&"
export * from "./ManageIdentificationSelect.vue?vue&type=script&lang=js&"
import style0 from "./ManageIdentificationSelect.scss?vue&type=style&index=0&id=50576022&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50576022",
  null
  
)

export default component.exports