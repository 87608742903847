<template src="./Profile.html" />
<style lang="scss" scoped src="./Profile.scss"></style>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import useMe from '@/comporsables/useMe'
import useOffice from '@/comporsables/useOffice'
import HeaderRight from '@/compornents/HeaderRight/HeaderRight'
import ProfileView from '@/compornents/ProfileView/ProfileView'
import ProfileModal from '@/compornents/ProfileModal/ProfileModal'
import AvatarImg from '@/compornents/AvatarImg/AvatarImg'

export default defineComponent({
  name: 'Profile',
  title() {
    return 'プロフィール'
  },
  components: {
    HeaderRight,
    ProfileView,
    ProfileModal,
    AvatarImg
  },
  setup(_, { root }) {
    const isSp = computed(() => root.$store.getters.isSp)
    const { me } = useMe(root)
    const { office, updateOfficeData } = useOffice(root)

    updateOfficeData(me) // meからofficeを取得

    return {
      isSp,
      me,
      office
    }
  }
})
</script>
