<template src="./ProfileRegister.html" />
<style lang="scss" scoped src="./ProfileRegister.scss"></style>
<script>
import {
  computed,
  defineComponent,
  reactive,
  ref,
  watch,
  onMounted
} from '@vue/composition-api'
import useMe from '@/comporsables/useMe'
import { GENDER_LIST, COPY_RIGHT } from '@/constants/constant'
import {
  GET_USER_SEARCH,
  POST_USER,
  GET_OFFICE_SEARCH,
  GET_OTHER_OFFICE_SEARCH
} from '@/store/types'
import { getThisYear } from '@/utils/date'
import '@/comporsables/useVeeValidate'
import VueRecaptcha from 'vue-recaptcha'

export default defineComponent({
  name: 'ProfileRegister',
  title() {
    return '新規会員登録'
  },
  components: {
    VueRecaptcha
  },
  setup(_, { root }) {
    const recaptchaSiteKey = ref(process.env.VUE_APP_RECAPTCHA_SITE_KEY_V2)
    const { me } = useMe(root)
    if (!me.is_login) {
      root.$router.push({ name: 'login' })
    }
    if (me.last_name) {
      root.$router.push({ name: 'home' })
    }
    if (parseInt(me.is_staff) != 1) {
      root.$router.push({ name: 'home' })
    }
    if (parseInt(me.is_family) === 0) {
      root.$router.push({ name: 'home' })
    }
    const office = ref({
      postcode: '',
      prefecture_id: '',
      city: '',
      block: '',
      building: '',
      other_sub_category_name: '',
      office_number: '',
      other_office_number: '',
      organization_name: '',
      homepage_url: '',
      office_phone: '',
      category_id: '',
      sub_category_id: ''
    })
    const loadOfficeData = () => {
      console.log('Office function called')

      // 介護事業所の場合：office_numberがNULLでない
      if (me.office_number) {
        root.$store
          .dispatch(GET_OFFICE_SEARCH, me.office_number)
          .then(response => {
            console.log('Office Search Response:', response)

            // APIレスポンスのデータをofficeオブジェクトに設定
            office.value.postcode = response.data.postcode || ''
            office.value.prefecture_id = response.data.prefecture_id || ''
            office.value.city = response.data.city || ''
            office.value.block = response.data.block || ''
            office.value.building = response.data.building || ''
            office.value.other_sub_category_name =
              response.data.other_sub_category_name || ''
            office.value.office_number = response.data.office_number || ''
            office.value.other_office_number =
              response.data.other_office_number || ''
            office.value.organization_name = response.data.company_name || ''
            office.value.homepage_url = response.data.url1 || ''
            office.value.office_phone = response.data.office_phone || ''
            office.value.category_id = response.data.category_id || ''
            office.value.sub_category_id = response.data.sub_category_id || ''

            console.log('Updated office object:', office.value)
          })
          .catch(error => {
            console.error('Error during office search:', error)
          })
        // 非介護事業所の場合：office_numberがNULL(other_office_numberがNULLでない)
      } else {
        root.$store
          .dispatch(GET_OTHER_OFFICE_SEARCH, me.other_office_number)
          .then(response => {
            console.log('Other Office Search Response:', response)

            // APIレスポンスのデータをofficeオブジェクトに設定
            office.value.postcode = response.data.postcode || ''
            office.value.prefecture_id = response.data.prefecture_id || ''
            office.value.city = response.data.city || ''
            office.value.block = response.data.block || ''
            office.value.building = response.data.building || ''
            office.value.other_sub_category_name =
              response.data.other_sub_category_name || ''
            office.value.office_number = response.data.office_number || ''
            office.value.other_office_number =
              response.data.other_office_number || ''
            office.value.organization_name = response.data.company_name || ''
            office.value.homepage_url = response.data.url1 || ''
            office.value.office_phone = response.data.office_phone || ''
            office.value.category_id = response.data.category_id || ''
            office.value.sub_category_id = response.data.sub_category_id || ''

            console.log('Updated office object:', office.value)
          })
          .catch(error => {
            console.error('Error during other office search:', error)
          })
      }
    }
    // onMounted(() => {
    //   loadOfficeData()
    // })

    const step = computed(() => parseInt(root.$route.params.step))
    const form = reactive({
      last_name: '',
      last_name_kana: '',
      first_name: '',
      first_name_kana: '',
      min_care_id: '',
      email: me.email,
      gender_id: 'male',
      year: '1970',
      month: '01',
      day: '01',
      position: '',
      qualification: '',
      office_number: '',
      other_office_number: '',
      organization_name: '',
      postcode: '',
      prefecture_id: '',
      city: '',
      block: '',
      building: '',
      homepage_url: '',
      business_phone: '',
      home_phone: '',
      mobile_phone: '',
      agreement: false
    })
    if (step.value > 1) {
      root.$router.push({ name: 'profile_register', params: { step: 1 } })
    }

    const clickNext = next_step => {
      root.$router.push({
        name: 'profile_register',
        params: { step: next_step }
      })
    }

    const clickPrev = () => {
      if (step.value === 1) {
        return
      }
      root.$router.push({
        name: 'profile_register',
        params: { step: step.value - 1 }
      })
    }

    const genderName = computed(
      () => GENDER_LIST.find(g => g.value === form.gender_id).label
    )

    const postProfile = () => {
      let param = {
        postcode: office.value.postcode,
        prefecture_id: office.value.prefecture_id,
        city: office.value.city,
        block: office.value.block,
        building: office.value.building,
        birthday: `${form.year}-${form.month}-${form.day}`,
        other_sub_category_name: office.value.other_sub_category_name,
        office_number: parseInt(office.value.office_number),
        other_office_number: parseInt(office.value.other_office_number),
        first_name: form.first_name,
        first_name_kana: form.first_name_kana,
        gender_id: form.gender_id,
        last_name: form.last_name,
        last_name_kana: form.last_name_kana,
        min_care_id: form.min_care_id,
        organization_name: office.value.organization_name,
        position: form.position,
        qualification: form.qualification,
        homepage_url: office.value.homepage_url,
        office_phone: office.value.office_phone,
        recaptchaToken: recaptchaToken,
        category_id: office.value.category_id,
        sub_category_id: office.value.sub_category_id
      }
      root.$store
        .dispatch(POST_USER, param)
        .then(() => location.reload())
        .catch(() => undefined)
    }

    const thisYear = getThisYear()
    let years = []
    for (let i = 1950; i <= thisYear; i++) {
      years = [...years, i]
    }

    const minCareIdValid = ref('')

    watch(
      () => form.min_care_id,
      v => {
        if (v.length < 6) {
          return
        }
        minCareIdValid.value = ''
        root.$store
          .dispatch(GET_USER_SEARCH, form.min_care_id)
          .then(() => {
            minCareIdValid.value = root.$store.getters.user ? 'NG' : 'OK'
          })
          .catch(() => {
            minCareIdValid.value = 'NG'
          })
      }
    )

    const recaptchaToken = ref('')
    const onVerify = response => {
      if (response !== '') {
        recaptchaToken.value = response
      }
    }

    // const recaptcha = ref(null)
    // onMounted(() => {
    //   console.log(recaptcha.value)
    // })

    const onExpired = () => {
      // console.log(recaptcha.value.reset())
      recaptchaToken.value = ''
    }

    const loading = computed(() => root.$store.getters.loading)

    onMounted(() => {
      loadOfficeData()
    })

    return {
      loading,
      me,
      GENDER_LIST,
      recaptchaSiteKey,
      step,
      form,
      genderName,
      years,
      minCareIdValid,
      recaptchaToken,
      clickNext,
      clickPrev,
      postProfile,
      onVerify,
      onExpired,
      COPY_RIGHT,
      office,
      loadOfficeData,
      onMounted
    }
  }
})
</script>
