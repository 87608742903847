import { render, staticRenderFns } from "./OfficeIdentificationStatus.html?vue&type=template&id=1040ffa1&scoped=true&"
import script from "./OfficeIdentificationStatus.vue?vue&type=script&lang=js&"
export * from "./OfficeIdentificationStatus.vue?vue&type=script&lang=js&"
import style0 from "./OfficeIdentificationStatus.scss?vue&type=style&index=0&id=1040ffa1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1040ffa1",
  null
  
)

export default component.exports